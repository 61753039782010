import { List } from 'immutable';

interface RouletteNumber {
    type: 'number' | 'bet';
    parity?: 'odd' | 'even';
    color?: 'red' | 'black';
    value: number | string;
}

const _table: Array<RouletteNumber> = [
    { type: 'number', value: 3, parity: 'odd', color: 'red' },
    { type: 'number', value: 6, parity: 'even', color: 'black' },
    { type: 'number', value: 9, parity: 'odd', color: 'red' },
    { type: 'number', value: 12, parity: 'even', color: 'red' },
    { type: 'number', value: 15, parity: 'odd', color: 'black' },
    { type: 'number', value: 18, parity: 'even', color: 'red' },
    { type: 'number', value: 21, parity: 'odd', color: 'red' },
    { type: 'number', value: 24, parity: 'even', color: 'black' },
    { type: 'number', value: 27, parity: 'odd', color: 'red' },
    { type: 'number', value: 30, parity: 'even', color: 'red' },
    { type: 'number', value: 33, parity: 'odd', color: 'black' },
    { type: 'number', value: 36, parity: 'even', color: 'red' },
    { type: 'bet', value: '2:1',},
    { type: 'number', value: 2, parity: 'even', color: 'black' },
    { type: 'number', value: 5, parity: 'odd', color: 'red' },
    { type: 'number', value: 8, parity: 'even', color: 'black' },
    { type: 'number', value: 11, parity: 'odd', color: 'black' },
    { type: 'number', value: 14, parity: 'even', color: 'red' },
    { type: 'number', value: 17, parity: 'odd', color: 'black' },
    { type: 'number', value: 20, parity: 'even', color: 'black' },
    { type: 'number', value: 23, parity: 'odd', color: 'red' },
    { type: 'number', value: 26, parity: 'even', color: 'black' },
    { type: 'number', value: 29, parity: 'odd', color: 'black' },
    { type: 'number', value: 32, parity: 'even', color: 'red' },
    { type: 'number', value: 35, parity: 'odd', color: 'black' },
    { type: 'bet', value: '2:1',},
    { type: 'number', value: 1, parity: 'odd', color: 'red' },
    { type: 'number', value: 4, parity: 'even', color: 'black' },
    { type: 'number', value: 7, parity: 'odd', color: 'red' },
    { type: 'number', value: 10, parity: 'even', color: 'black' },
    { type: 'number', value: 13, parity: 'odd', color: 'black' },
    { type: 'number', value: 16, parity: 'even', color: 'red' },
    { type: 'number', value: 19, parity: 'odd', color: 'red' },
    { type: 'number', value: 22, parity: 'even', color: 'black' },
    { type: 'number', value: 25, parity: 'odd', color: 'red' },
    { type: 'number', value: 28, parity: 'even', color: 'black' },
    { type: 'number', value: 31, parity: 'odd', color: 'black' },
    { type: 'number', value: 34, parity: 'even', color: 'red' },
    { type: 'bet', value: '2:1',},
]

export const rouletteTable = List(_table)