import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

interface WordleGameProps { };

const WordleGame = (props: WordleGameProps): JSX.Element => {

    const [currentGuess, setCurrentGuess] = useState<number>(0);


    const [guess1, setGuess1] = useState<Array<string | null>>([null, null, null, null, null])
    const [guess2, setGuess2] = useState<Array<string | null>>([null, null, null, null, null])
    const [guess3, setGuess3] = useState<Array<string | null>>([null, null, null, null, null])
    const [guess4, setGuess4] = useState<Array<string | null>>([null, null, null, null, null])
    const [guess5, setGuess5] = useState<Array<string | null>>([null, null, null, null, null])

    const [alphabet, setAlphabet] = useState<object>({
        a: false,
        b: false,
        c: false,
        d: false,
        e: false,
        f: false,
        g: false,
        h: false,
        i: false,
        j: false,
        k: false,
        l: false,
        m: false,
        n: false,
        o: false,
        p: false,
        q: false,
        r: false,
        s: false,
        t: false,
        u: false,
        v: false,
        w: false,
        x: false,
        y: false,
        z: false
    })

    const checkGuess = () => {

    }

    useEffect(() => {
        window.onkeyup = (e) => {
            let key = e.key.toUpperCase();
            if ('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.includes(key)) {
                switch (currentGuess) {
                    case 0: {
                        const index = guess1.findIndex((v) => v === null);
                        if (index !== -1) {
                            let newArr = Array.from(guess1)
                            newArr[index] = key;
                            setGuess1(() => [...newArr]);
                        }
                        break;
                    }
                    case 1: {
                        const index = guess2.findIndex((v) => v === undefined);
                        if (index !== undefined) {
                            guess2[index] = key;
                        }
                        break;
                    }
                    case 2: {
                        const index = guess3.findIndex((v) => v === undefined);
                        if (index !== undefined) {
                            guess3[index] = key;
                        }
                        break;
                    }
                    case 3: {
                        const index = guess4.findIndex((v) => v === undefined);
                        if (index !== undefined) {
                            guess4[index] = key;
                        }
                        break;
                    }
                    case 4: {
                        const index = guess1.findIndex((v) => v === undefined);
                        if (index !== undefined) {
                            guess5[index] = key;
                        }
                        break;
                    }
                }
            } else if (key === 'ENTER') {
                checkGuess()
            } else if (key === 'BACKSPACE') {

            }
        }
        return () => {
            window.onkeyup = null;
        }
    }, [])

    const renderGuess1 = useCallback((): ReactNode => {
        return (
            <>
                <div className={`flex justify-evenly mx-auto gap-x-2 gap-y-1`}>
                    {guess1.map((c, cdx) => (
                        <>
                            <div key={cdx} className={`border border-2 border-slate-700 w-14 h-14 border rounded-md text-white font-medium flex m-auto`}>
                                <div className={`w-full m-auto`}>
                                    <p className={`text-center text-xl`}>{c}</p>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </>
        )
    }, [guess1])

    return (
        <>
            <Helmet>
                <title>Wordle | Droplime</title>
            </Helmet>
            <main className={`bg-slate-900 h-screen`}>
                <div className='md:w-1/3 mx-auto'>
                    <section id={`guesses`} className={`py-3`}>
                        <div className='space-y-5 mx-auto'>
                            <div className='w-full'>
                                <div className={`flex flex-row justify-evenly gap-x-2 gap-y-1`}>
                                    <p className={`text-white`}>{JSON.stringify(guess1)}</p>
                                    {renderGuess1()}
                                </div>
                            </div>
                            <div className={`flex justify-evenly mx-auto gap-x-2 gap-y-1`}>
                                <div className={`flex justify-evenly mx-auto gap-x-2 gap-y-1`}>
                                    {guess2.map((c, cdx) => (
                                        <>
                                            {c === null ? (
                                                <>
                                                    <div key={cdx} className={`border border-2 border-slate-700 w-14 h-14 border rounded-md`}>

                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div key={cdx} className={`p-2 border`}>
                                                        {c}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className={`flex justify-evenly mx-auto gap-x-2 gap-y-1`}>
                                <div className={`flex justify-evenly mx-auto gap-x-2 gap-y-1`}>
                                    {guess3.map((c, cdx) => (
                                        <>
                                            {c === null ? (
                                                <>
                                                    <div key={cdx} className={`border border-2 border-slate-700 w-14 h-14 border rounded-md`}>

                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div key={cdx} className={`col-span-1 p-2 border`}>
                                                        {c}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className={`flex justify-evenly mx-auto gap-x-2 gap-y-1`}>
                                <div className={`flex justify-evenly mx-auto gap-x-2 gap-y-1`}>
                                    {guess4.map((c, cdx) => (
                                        <>
                                            {c === null ? (
                                                <>
                                                    <div key={cdx} className={`border border-2 border-slate-700 w-14 h-14 border rounded-md`}>

                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div key={cdx} className={`col-span-1 p-2 border`}>
                                                        {c}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </div >
                            <div className={`flex justify-evenly mx-auto gap-x-2 gap-y-1`}>
                                <div className={`flex justify-evenly mx-auto gap-x-2 gap-y-1`}>
                                    {guess5.map((c, cdx) => (
                                        <>
                                            {c === null ? (
                                                <>
                                                    <div key={cdx} className={`border border-2 border-slate-700 w-14 h-14 border rounded-md`}>

                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div key={cdx} className={`col-span-1 p-2 border`}>
                                                        {c}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id={`alphabet`} className='grid grid-cols-7 gap-x-3 gap-y-4 p-5'>
                        {Object.keys(alphabet).map((i, idx) => (
                            <>
                                <div key={idx} className={`bg-slate-600 text-white font-medium text-xl col-span-1 py-4 px-1 rounded-md text-center`}>
                                    {i.toUpperCase()}
                                </div>
                            </>
                        ))}
                    </section>
                </div>
            </main>
        </>
    )
}

export default WordleGame;