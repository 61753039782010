import { Map } from 'immutable';

export type KenoDifficulty = 1 | 2 | 3 | 4;
export type KenoSelectionCount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
export type KenoCorrectSelections = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

const multipliers = Map({
    // classic difficulty
    classic: {
        1: {
            0: 0,
            1: 3.96
        },
        2: {
            0: 0,
            1: 1.9,
            2: 4.5
        },
        3: {
            0: 0,
            1: 1,
            2: 3.1,
            3: 10.4
        },
        4: {
            0: 0,
            1: 0.8,
            2: 1.8,
            3: 5.0
        },
        5: {
            0: 0,
            1: 0.25,
            2: 1.4,
            3: 4.1,
            4: 16.5,
            5: 36.0,
        },
        6: {
            0: 0,
            1: 0,
            2: 1,
            3: 3.68,
            4: 7.0,
            5: 16.5,
            6: 40.0,
        },
        7: {
            0: 0,
            1: 0,
            2: 0.47,
            3: 3,
            4: 4.5,
            5: 14,
            6: 31,
            7: 60,
        },
        8: {
            0: 0,
            1: 0,
            2: 0,
            3: 2.2,
            4: 4,
            5: 13,
            6: 22,
            7: 55,
            8: 70,
        },
        9: {
            0: 0,
            1: 0,
            2: 0,
            3: 1.55,
            4: 3,
            5: 8,
            6: 15,
            7: 44,
            8: 60,
            9: 85,
        },
        10: {
            0: 0,
            1: 0,
            2: 0,
            3: 1.4,
            4: 2.25,
            5: 4.5,
            6: 8,
            7: 17,
            8: 50,
            9: 80,
            10: 100,
        }
    },
    // low difficulty
    low: {
        1: {
            0: 0.7,
            1: 1.85,
        },
        2: {
            0: 0,
            1: 2,
            2: 3.8,
        },
        3: {
            0: 0,
            1: 1.1,
            2: 1.38,
            3: 26,
        },
        4: {
            0: 0,
            1: 0,
            2: 2.2,
            3: 7.9,
            4: 90,
        },
        5: {
            0: 0,
            1: 0,
            2: 1.5,
            3: 3,
            4: 23,
            5: 300,
        },
        6: {
            0: 0,
            1: 0,
            2: 1.1,
            3: 2,
            4: 5,
            5: 105,
            6: 1000,
        },
        7: {
            0: 0,
            1: 0,
            2: 1.1,
            3: 1.6,
            4: 3.5,
            5: 15.0,
            6: 225,
            7: 700,
        },
        8: {
            0: 0,
            1: 0,
            2: 1.1,
            3: 1.5,
            4: 2.0,
            5: 5.5,
            6: 39,
            7: 100,
            8: 800,
        },
        9: {
            0: 0,
            1: 0,
            2: 1.1,
            3: 1.3,
            4: 1.7,
            5: 2.5,
            6: 7.5,
            7: 50,
            8: 250,
            9: 1000,
        },
        10: {
            0: 0,
            1: 0,
            2: 1.1,
            3: 1.2,
            4: 1.3,
            5: 1.8,
            6: 3.5,
            7: 13.0,
            8: 50.0,
            9: 250.0,
            10: 1000.0,
        }
    },
    // medium difficulty
    medium: {
        1: {
            0: 0.4,
            1: 2.75,
        },
        2: {
            0: 0.0,
            1: 1.8,
            2: 5.1,
        },
        3: {
            0: 0,
            1: 0,
            2: 2.8,
            3: 50.0,
        },
        4: {
            0: 0,
            1: 0,
            2: 1.7,
            3: 10.0,
            4: 100.0,
        },
        5: {
            0: 0,
            1: 0,
            2: 1.4,
            3: 4.0,
            4: 14.0,
            5: 390.0,
        },
        6: {
            0: 0,
            1: 0,
            2: 0,
            3: 3.0,
            4: 9.0,
            5: 180.0,
            6: 710.0,
        },
        7: {
            0: 0,
            1: 0,
            2: 0,
            3: 2.0,
            4: 7.0,
            5: 30.0,
            6: 400.0,
            7: 800.0,
        },
        8: {
            0: 0,
            1: 0,
            2: 0,
            3: 2.0,
            4: 4.0,
            5: 11.0,
            6: 67.0,
            7: 400.0,
            8: 900.0,
        },
        9: {
            0: 0,
            1: 0,
            2: 0,
            3: 2.0,
            4: 2.5,
            5: 5,
            6: 15.0,
            7: 100.0,
            8: 500.0,
            9: 1000.0,
        },
        10: {
            0: 0,
            1: 0,
            2: 0,
            3: 1.6,
            4: 2,
            5: 4,
            6: 7,
            7: 26.0,
            8: 100.0,
            9: 500.0,
            10: 1000.0,
        }
    },
    // high difficulty
    high: {
        1: {
            0: 0,
            1: 3.96,
        },
        2: {
            0: 0,
            1: 0,
            2: 17.1,
        },
        3: {
            0: 0,
            1: 0,
            2: 0,
            3: 81.5,
        },
        4: {
            0: 0,
            1: 0,
            2: 0,
            3: 10.0,
            4: 259.0,
        },
        5: {
            0: 0,
            1: 0,
            2: 0,
            3: 4.5,
            4: 48.0,
            5: 450.0,
        },
        6: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 11.0,
            5: 350.0,
            6: 710.0,
        },
        7: {
            0: 0.0,
            1: 0.0,
            2: 0.0,
            3: 0.0,
            4: 7.0,
            5: 90.0,
            6: 400.0,
            7: 800.0,
        },
        8: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 5.0,
            5: 20.0,
            6: 270.0,
            7: 600.0,
            8: 900.0,
        },
        9: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 4.0,
            5: 11.0,
            6: 56.0,
            7: 500.0,
            8: 800.0,
            9: 1000.0,
        },
        10: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 3.5,
            5: 8.0,
            6: 13.0,
            7: 63.0,
            8: 500.0,
            9: 800.0,
            10: 1000.0,
        }
    },
})

const difficultyNames = ['classic', 'low', 'medium', 'high']

export const getDifficultyMultipliers = (difficulty: KenoDifficulty, selections: KenoSelectionCount): object => {
    // @ts-ignore
    const table = multipliers.get(difficultyNames[difficulty - 1] as string)
    if (Object.hasOwn(table, selections)) {
        // @ts-ignore
        return table[selections] as number
    } else {
        throw new Error('invalid keno selection count')
    }
}

export const getMultiplier = (difficulty: KenoDifficulty, selections: KenoSelectionCount, index: KenoCorrectSelections): number => {
    // @ts-ignore
    const table = multipliers.get(difficultyNames[difficulty + 1] as string)[selections]
    if (Object.hasOwn(table, index)) {
        // @ts-ignore
        return table[index] as number
    } else {
        throw new Error('invalid keno selection count')
    }
}