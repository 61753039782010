import { useQueryParams } from "../../hooks/useQueryParams";
import { Tab } from "@headlessui/react";
import { FormikConfig, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import CountUp from 'react-countup';
import { Helmet } from "react-helmet";

interface CrashMessage {
    type: number;
    data: any;
}

type Point = { x: number, y: number }

interface CrashGameProps { };

/**
 * Crash is a game that allows users to play in 
 * realtime against an outcome.
 */
const CrashGame = (props: CrashGameProps): JSX.Element => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [multiplier, setMultiplier] = useState<number>(1.0);
    const [start, setStart] = useState<Date>(new Date());
    const [outcomeHistory, setOutcomeHistory] = useState<Array<number>>([]);
    const params = useQueryParams();
    const [multiplierAnimationInterval, setMultiplierAnimationInterval] = useState<NodeJS.Timer>();
    const ws = new WebSocket(`${process.env.REACT_APP_CRASH_WS_URL as string}?token=${params.get('token')}`)

    ws.onopen = () => {
        setDisabled(false);
    }

    ws.onmessage = (message) => {
        const payload = JSON.parse(message.data) as CrashMessage;
        console.log(payload);
        switch (payload.type) {
            // outcome
            case 2: {
                endRound();
                setMultiplier(payload.data.outcome);
                setOutcomeHistory((prev) => {
                    prev.push(payload.data.outcome)
                    return prev;
                })
                break;
            }
            // next round
            case 7: {
                startRound(payload.data.starts_at);
                break;
            }
        }
    }

    const firstWagerForm = useFormik({
        initialValues: {
            wager: 10,
        },
        onSubmit: async (values, { setSubmitting }) => {

        }
    })

    const secondWagerForm = useFormik({
        initialValues: {
            wager: 10
        },
        onSubmit: async (values, { setSubmitting }) => {

        }
    })

    const startRound = (startTimestamp: number) => {
        setStart(new Date(startTimestamp * 1000));
        const multiplierAnimation = setInterval(() => {
            const now = new Date();
            var differenceFromRoundStartSeconds = (now.getTime() - start.getTime()) / 1000;
            if (differenceFromRoundStartSeconds < 20) {
                setMultiplier(multiplier + Math.pow(Math.E, differenceFromRoundStartSeconds / 10) - 1)
            } else {
                setMultiplier(multiplier + Math.pow(Math.E, differenceFromRoundStartSeconds / 5) - 1)
            }
        }, 10)
        setMultiplierAnimationInterval(multiplierAnimation);
    }

    const endRound = () => {
        clearInterval(multiplierAnimationInterval);
    }

    useEffect(() => {
        return () => {
            clearInterval(multiplierAnimationInterval)
            window.removeEventListener('message', () => null);
        }
    }, [])

    const halvePlayAmount = () => {

    }

    const doublePlayAmount = () => {

    }

    const CrashSettings = (
        <>
            <div className="w-full p-2">
                <Tab.Group>
                    <Tab.List className={`w-full flex justify-evenly focus:outline-none`}>
                        <Tab className={`w-full focus:outline-none`}>
                            {({ selected }) => (
                                /* Use the `selected` state to conditionally style the selected tab. */
                                <button
                                    className={
                                        `${selected ? 'text-emerald-500 border-b-emerald-500' : 'border-b border-slate-600 text-slate-200'} px-5 py-4 w-full font-medium border-b-2 border-emerald-700`
                                    }
                                >
                                    All
                                </button>
                            )}
                        </Tab>
                        <Tab className={`w-full focus:outline-none`}>
                            {({ selected }) => (
                                <button
                                    className={
                                        `${selected ? 'text-emerald-500 border-b-emerald-500' : 'border-b border-slate-600 text-slate-200'} px-5 py-4 w-full font-medium border-b-2 border-emerald-700`
                                    }
                                >
                                    History
                                </button>
                            )}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels className={`py-5 px-1`}>
                        <Tab.Panel className={`w-full`}>

                        </Tab.Panel>
                        <Tab.Panel>
                            <section id="wager-history">
                                <label htmlFor='wagerHistory' className='text-md dark:text-white font-medium mb-2'>Period</label>
                                <select
                                    id="crash-wager-select"
                                    name={'wagerHistory'}
                                    className="w-full py-2 appearance-none rounded-md border-2 border-slate-600 bg-slate-900 px-3 text-white font-medium">
                                    <option value={1}>Today</option>
                                    <option value={2}>Month</option>
                                    <option value={3}>Year</option>
                                    <option value={4}>All-time</option>
                                </select>
                            </section>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </>
    )

    const quickWager = (form: FormikConfig<any>, amount: number) => {

    }

    return (
        <>
            <Helmet>
                <title>Crash | Mocash</title>
            </Helmet>
            <main className="bg-slate-800">
                <article className="grid grid-cols-12">
                    <div className="col-span-4 bg-slate-900">
                        {CrashSettings}
                    </div>
                    <div className="col-span-8">
                        <section className="flex justify-end overflow-x-scroll gap-x-3 py-4">
                            {outcomeHistory.map((i, idx) => (
                                <>
                                    <article className="px-3 py-2 font-medium">
                                        {i.toFixed(2)}
                                    </article>
                                </>
                            ))}
                        </section>
                        <div className="py-44 flex justify-center">
                            <p className="text-6xl font-medium text-white">{multiplier.toFixed(2)}</p>
                        </div>
                        <section id="wagers" className="grid grid-cols-12 gap-x-3 px-3 py-5">
                            <div className="col-span-6">
                                <form onSubmit={firstWagerForm.handleSubmit}>
                                    {/** bet options */}
                                    <div className="py-2">
                                        <div className={`flex justify-start mt-3`}>
                                            <input
                                                onChange={firstWagerForm.handleChange}
                                                value={firstWagerForm.values.wager}
                                                disabled={firstWagerForm.isSubmitting}
                                                name={`Wager amount`}
                                                type={`number`}
                                                min={1}
                                                placeholder='amount'
                                                className={`text-white font-medium flex-8 w-full bg-slate-900 rounded-l-md border-l-2 border-t-2 border-b-2 border-slate-600 py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                            <button
                                                onClick={halvePlayAmount}
                                                className={`flex-1 px-3  hover:bg-emerald-500 text-xs font-medium border-2 border-slate-600 text-white bg-slate-900`}>½</button>
                                            <button
                                                onClick={doublePlayAmount}
                                                className={`flex-1 px-3 text-white bg-slate-900 hover:bg-emerald-500 text-xs font-medium border-r-2 border-t-2 border-b-2 border-slate-600 rounded-r-md`}>2x</button>
                                        </div>
                                    </div>
                                    <div className="grid sm:grid-cols-2 md:grid-cols-4 py-3 gap-x-3">
                                        <button className="col-span-1 text-white font-medium text-center px-3 py-2 bg-gray-900 rounded-md">
                                            25
                                        </button>
                                        <button className="col-span-1 text-white font-medium text-center px-3 py-2 bg-gray-900 rounded-md">
                                            50
                                        </button>
                                        <button className="col-span-1 text-white font-medium text-center px-3 py-2 bg-gray-900 rounded-md">
                                            100
                                        </button>
                                        <button className="col-span-1 text-white font-medium text-center px-3 py-2 bg-gray-900 rounded-md">
                                            200
                                        </button>
                                    </div>
                                    <button
                                        id="crash-wager-1"
                                        type="submit"
                                        className={`px-5 py-3 w-full bg-green-500 hover:bg-green-400 rounded-md font-medium text-white text-sm`}>
                                        Play
                                    </button>
                                </form>
                            </div>
                            <div className="col-span-6">
                                <form onSubmit={secondWagerForm.handleSubmit}>
                                    {/** bet options */}
                                    <div className="py-2">
                                        <div className={`flex justify-start mt-3`}>
                                            <input
                                                onChange={secondWagerForm.handleChange}
                                                value={secondWagerForm.values.wager}
                                                disabled={secondWagerForm.isSubmitting}
                                                name={`Wager amount`}
                                                type={`number`}
                                                min={1}
                                                placeholder='amount'
                                                className={`text-white font-medium flex-8 w-full bg-slate-900 rounded-l-md border-l-2 border-t-2 border-b-2 border-slate-600 py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                            <button
                                                onClick={halvePlayAmount}
                                                className={`flex-1 px-3  hover:bg-emerald-500 text-xs font-medium border-2 border-slate-600 text-white bg-slate-900`}>½</button>
                                            <button
                                                onClick={doublePlayAmount}
                                                className={`flex-1 px-3 text-white bg-slate-900 hover:bg-emerald-500 text-xs font-medium border-r-2 border-t-2 border-b-2 border-slate-600 rounded-r-md`}>2x</button>
                                        </div>
                                    </div>
                                    <div className="grid sm:grid-cols-2 md:grid-cols-4 py-3 gap-x-3">
                                        <button className="col-span-1 text-white font-medium text-center px-3 py-2 bg-gray-900 hover:bg-gray-950 rounded-md">
                                            25
                                        </button>
                                        <button className="col-span-1 text-white font-medium text-center px-3 py-2 bg-gray-900  hover:bg-gray-950  rounded-md">
                                            50
                                        </button>
                                        <button className="col-span-1 text-white font-medium text-center px-3 py-2 bg-gray-900 hover:bg-gray-950  rounded-md">
                                            100
                                        </button>
                                        <button className="col-span-1 text-white font-medium text-center px-3 py-2 bg-gray-900 hover:bg-gray-950 rounded-md">
                                            200
                                        </button>
                                    </div>
                                    <button
                                        id="crash-wager-2"
                                        type="submit"
                                        className={`px-5 py-3 w-full bg-green-500 hover:bg-green-400 rounded-md font-medium text-white text-sm`}>
                                        Play
                                    </button>
                                </form>
                            </div>
                        </section>
                    </div>
                </article>
            </main>
        </>
    )
}

export default CrashGame;