import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import debounce from 'lodash.debounce'

interface DiceGameProps { };

const DiceGame = (props: DiceGameProps): JSX.Element => {

    const [isAutoPlay, setIsAutoPlay] = useState<boolean>(false);
    const [autoPlayCount, setAutoPlayCount] = useState<number>(0);
    const [outcome, setOutcome] = useState<number | undefined>(50);

    const form = useFormik({
        initialValues: {
            amount: 10,
            win_profit: undefined,
            required_outcome: 50.5,
            under: false,
            multiplier: 2.0,
            win_chance: 49.5000,
            remaining_plays: 50,
            is_autoplay: false,
        },
        validate: ({ multiplier }) => {
            if (multiplier < 1.0102) {

            }
        },
        onSubmit: async (values, { setSubmitting }) => { }
    })


    const halvePlayAmount = () => {
        const newPlayAmount = form.values.amount / 2
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    const doublePlayAmount = () => {
        const newPlayAmount = form.values.amount * 2
        // todo add balance
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    const toggleRollType = () => {
        const rollType = !form.values.under
        form.setFieldValue('under', rollType)
        form.setFieldValue('win_chance', ((100 / form.values.multiplier) - 0.5).toFixed(4))
    }

    const checkMultipliers = (): void => {
        if (form.values.multiplier >= 1.0102 && form.values.multiplier <= 9900) {
            form.setFieldValue('win_chance', ((100 / form.values.multiplier) - 0.5).toFixed(4))
            form.setFieldValue('win_profit', ((form.values.amount * form.values.multiplier) - form.values.amount).toFixed(4))
        } else {
            if (form.values.multiplier > 9900) {
                form.setFieldValue(`multiplier`, 9900.0)
            } else if (form.values.multiplier < 1.0102) {
                form.setFieldValue(`multiplier`, 1.0102)
            }
        }
    }

    useEffect(() => {
        checkMultipliers()
    }, [form.values.multiplier])

    useEffect(() => {
        form.setFieldValue('win_profit', ((form.values.amount * form.values.multiplier) - form.values.amount).toFixed(4))
    }, [form.values.amount])

    useEffect(() => {
        const winChangeInput = document.getElementById('win_chance') as HTMLInputElement;
        winChangeInput.addEventListener(`change`, (ev: any) => {
            const value = Number.parseFloat(ev.target.value);
            form.setFieldValue('multiplier', ((100 / value) - 0.05).toFixed(4));
        });
        return () => {
            winChangeInput.removeEventListener(`change`, () => { })
        }
    }, [])



    const standardFields = (
        <>
            <div className={`grid md:grid-cols-3 gap-x-3 py-4 bg-slate-800`}>
                <div className={`col-span-1 container py-2 rounded-sm`}>
                    <label htmlFor='required_outcome' className='text-sm font-medium mb-2 text-white'>{form.values.under ? 'Roll under' : 'Roll over'}</label>
                    <button
                        onChange={form.handleChange}
                        disabled={form.isSubmitting}
                        onClick={toggleRollType}
                        name={`required_outcome`} placeholder={`Roll`} className={`bg-white my-2 w-full rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`}>
                        {form.values.under ? (100 / form.values.multiplier - 0.5).toFixed(2) : (100 - (100 / form.values.multiplier) + 0.5 - 0.01).toFixed(2)}
                    </button>
                </div>
                <div className={`col-span-1 container py-2`}>
                    <label htmlFor='multiplier' className='text-sm font-medium mb-2 text-white'>Multiplier</label>
                    <input
                        onChange={form.handleChange}
                        value={form.values.multiplier}
                        disabled={form.isSubmitting}
                        name={`multiplier`}
                        type={`number`}
                        placeholder={`Multiplier`}
                        min={1.0102}
                        step={0.5}
                        className={`my-2 w-full text-center rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                </div>
                <div className={`col-span-1 container py-2`}>
                    <label htmlFor='win_chance' className='text-sm font-medium mb-2 text-white'>Win chance</label>
                    <input
                        id={`win_chance`}
                        onChange={form.handleChange}
                        value={form.values.win_chance}
                        disabled={form.isSubmitting}
                        step={1}
                        min={0.01}
                        max={98}
                        type={`number`}
                        name={`win_chance`}
                        placeholder={`Probability`}
                        className={`my-2 w-full font-medium text-center rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                </div>
            </div>
        </>
    );

    useEffect(() => {
        window.addEventListener(`message`, (event) => {
            if (event.data) {
                //const data = JSON.parse(event.data);
            }
        })
        return () => {
            window.removeEventListener(`message`, (event) => { });
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Dice - Mocash</title>
            </Helmet>
            <main className='m-auto py-4 px-3 md:px-10 bg-slate-900'>
                <article className='md:w-2/3 mx-auto'>
                    <div className={`border border-slate-900 rounded-md`}>
                        <Tab.Group
                            onChange={(index) => {
                                form.setFieldValue(`is_autoplay`, index === 1);
                            }}
                        >
                            <Tab.List className={`flex justify-evenly focus:outline-none`}>
                                <Tab className={`w-full focus:outline-none rounded-t-md`}>
                                    {({ selected }) => (
                                        /* Use the `selected` state to conditionally style the selected tab. */
                                        <button
                                            className={
                                                `${selected ? 'bg-emerald-500 text-white' : 'bg-slate-600 text-white'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-700'} rounded-tl-md`
                                            }
                                        >
                                            Manual
                                        </button>
                                    )}
                                </Tab>
                                <Tab className={`w-full focus:outline-none`}>
                                    {({ selected }) => (
                                        <button
                                            className={
                                                `${selected ? 'bg-emerald-500 text-white' : 'bg-slate-600 text-white'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-700'} rounded-tr-md`
                                            }
                                        >
                                            Auto
                                        </button>
                                    )}
                                </Tab>
                            </Tab.List>
                            <Tab.Panels className={`p-5 bg-slate-800 rounded-b-md`}>
                                <Tab.Panel>
                                    <article className='rounded-sm'>
                                        <div className={`grid md:grid-cols-2 gap-x-5`}>
                                            <div className={`col-span-1`}>
                                                <label htmlFor='amount' className='text-sm font-medium mb-2 text-white'>Play amount</label>
                                                <div className={`flex justify-start my-3`}>
                                                    <input
                                                        onChange={form.handleChange}
                                                        value={form.values.amount}
                                                        disabled={form.isSubmitting}
                                                        name={`amount`}
                                                        type={`number`}
                                                        min={1}
                                                        step={10}
                                                        placeholder='amount'
                                                        className={`flex-8 text-center w-full rounded-l-sm border-l border-t border-b py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                                    <button
                                                        onClick={halvePlayAmount}
                                                        className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border`}>½</button>
                                                    <button
                                                        onClick={doublePlayAmount}
                                                        className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border-r border-t border-b rounded-r-sm`}>2x</button>
                                                </div>
                                            </div>
                                            <div className={`col-span-1`}>
                                                <label htmlFor='win_profit' className='text-sm font-medium mb-2 text-white'>Win profit</label>
                                                <input
                                                    onChange={form.handleChange}
                                                    value={form.values.win_profit}
                                                    disabled={true}
                                                    name={`win_profit`}
                                                    placeholder={`Coin win`}
                                                    className={`bg-white my-3 w-full text-center font-medium rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                            </div>
                                        </div>
                                        {standardFields}
                                        <button className={`px-5 py-4 w-full bg-emerald-500 hover:bg-emerald-600 rounded-sm font-medium text-white`}>Play game</button>
                                    </article>
                                </Tab.Panel>
                                <Tab.Panel>
                                    <div className={`grid md:grid-cols-2 gap-x-5`}>
                                        <div className={`col-span-1 container`}>
                                            <label htmlFor='amount' className='text-sm font-medium mb-2 text-white'>Play amount</label>
                                            <div className={`flex justify-start my-3`}>
                                                <input
                                                    onChange={form.handleChange}
                                                    value={form.values.amount}
                                                    disabled={form.isSubmitting}
                                                    name={`amount`}
                                                    type={`number`}
                                                    min={0.01}
                                                    placeholder='amount'
                                                    className={`flex-8 text-center w-full rounded-l-sm border-l border-t border-b py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                                <button
                                                    onClick={halvePlayAmount}
                                                    className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border`}>½</button>
                                                <button
                                                    onClick={doublePlayAmount}
                                                    className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border-r border-t border-b rounded-r-sm`}>2x</button>
                                            </div>
                                        </div>
                                        <div className={`col-span-1 container`}>
                                            <label htmlFor='remaining_plays' className='text-sm font-medium mb-2 text-white'>Number of plays</label>
                                            <input
                                                onChange={form.handleChange}
                                                value={form.values.remaining_plays}
                                                disabled={form.isSubmitting}
                                                name={`remaining_plays`}
                                                placeholder={`Select`}
                                                className={`my-3 w-full text-center rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                        </div>
                                    </div>
                                    {standardFields}
                                    <button className={`px-5 py-4 w-full bg-emerald-500 hover:bg-emerald-600 rounded-sm font-medium text-white`}>Auto play</button>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                    <div className={`pt-10 pb-5 text-center`}>
                        {outcome && (
                            <>
                                <p className={`font-bold text-6xl text-red-500`}>{outcome.toFixed(2)}</p>
                            </>
                        )}
                    </div>
                    <div className={`pt-5 pb-10 md:py-5`}>
                        <input type="range" min={1} max={99.9} className="accent-emerald-500 w-full py-10 h-3 outline-none border-none" value={form.values.under ? (100 / form.values.multiplier - 0.5).toFixed(2) : (100 - (100 / form.values.multiplier) + 0.5).toFixed(2)} />
                    </div>
                </article>
            </main>
        </>
    )
}

export default DiceGame;