import { useFormik } from "formik";
import { Tab } from '@headlessui/react'
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import Logo from "../../components/Logo";
import { Howl, Howler } from 'howler';
import { useCallback, useEffect } from "react";
import { CommonPageProps } from "../../lib/typings";
import { withAccesstoken } from "../../hocs/withAccessToken";
import { KenoCorrectSelections, KenoDifficulty, KenoSelectionCount, getDifficultyMultipliers, getMultiplier } from "../../lib/keno";


interface KenoGameProps extends CommonPageProps { };

const KenoGame = (props: KenoGameProps): JSX.Element => {

    const popSound = new Howl({
        src: ['/sounds/switch-tap.wav'],
        volume: 0.9
    });

    let bgSound: Howl;

    const items = Array(40).fill((i: number) => i).map((i, idx) => idx + 1);
    const form = useFormik<{ selections: Array<number>; amount: number; difficulty: number; }>({
        initialValues: {
            selections: [],
            amount: 10,
            difficulty: 3,
        },
        onSubmit: async (values, { setSubmitting }) => {

        },
    })


    const halvePlayAmount = () => {
        const newPlayAmount = form.values.amount / 2
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    const doublePlayAmount = () => {
        const newPlayAmount = form.values.amount * 2
        // todo add balance
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    const clear = () => {
        form.setFieldValue(`selections`, [])
    }

    const autoPick = () => {
        let remaining = 10 - form.values.selections.length;
        let newChoices: Array<number> = [];
        while (remaining !== 0) {
            const outcome = Math.round(Math.random() * 39) + 1;
            if (!form.values.selections.includes(outcome) || newChoices.includes(outcome)) {
                newChoices.push(outcome)
                remaining = remaining - 1;
            }
        }
        form.setFieldValue(`selections`, [...form.values.selections, ...newChoices])
    }

    const selectNumber = (i: number) => {
        if (form.values.selections.length < 10) {
            const el = document.getElementById(`keno-ball-${i}`) as HTMLDivElement;
            el.classList.add('btn-animate');
            form.setFieldValue(`selections`, [...form.values.selections, i])
            popSound.play();
        } else {
            toast.error('You have already selected 10 numbers')
        }
    }

    const deselectNumber = (i: number) => {
        form.setFieldValue(`selections`, form.values.selections.filter(v => v !== i))
        const el = document.getElementById(`keno-ball-${i}-selected`) as HTMLDivElement;
        el.classList.remove('btn-animate');
    }

    const play = async () => {

    }



    const KenoSettings = (
        <>
            <div className="w-full p-2">
                <Tab.Group>
                    <Tab.List className={`w-full flex justify-evenly focus:outline-none`}>
                        <Tab className={`w-full focus:outline-none`}>
                            {({ selected }) => (
                                /* Use the `selected` state to conditionally style the selected tab. */
                                <button
                                    className={
                                        `${selected ? 'text-emerald-500 border-b-emerald-500' : 'border-b border-slate-600 text-slate-200'} px-5 py-4 w-full font-medium border-b-2 border-emerald-700`
                                    }
                                >
                                    Manual
                                </button>
                            )}
                        </Tab>
                        <Tab className={`w-full focus:outline-none`}>
                            {({ selected }) => (
                                <button
                                    className={
                                        `${selected ? 'text-emerald-500 border-b-emerald-500' : 'border-b border-slate-600 text-slate-200'} px-5 py-4 w-full font-medium border-b-2 border-emerald-700`
                                    }
                                >
                                    Auto
                                </button>
                            )}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels className={`py-5 px-1`}>
                        <Tab.Panel className={`w-full`}>
                            <label htmlFor='amount' className='text-md dark:text-white font-medium mb-2'>Play amount</label>
                            <div className={`flex justify-start my-3`}>
                                <input
                                    onChange={form.handleChange}
                                    value={form.values.amount}
                                    disabled={form.isSubmitting}
                                    name={`amount`}
                                    type={`number`}
                                    min={1}
                                    step={10}
                                    placeholder='amount'
                                    className={`text-white font-medium flex-8 w-full bg-slate-900 rounded-l-md border-l-2 border-t-2 border-b-2 border-slate-600 py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                <button
                                    onClick={halvePlayAmount}
                                    className={`flex-1 px-3  hover:bg-emerald-500 text-xs font-medium border-2 border-slate-600 text-white bg-slate-900`}>½</button>
                                <button
                                    onClick={doublePlayAmount}
                                    className={`flex-1 px-3 text-white bg-slate-900 hover:bg-emerald-500 text-xs font-medium border-r-2 border-t-2 border-b-2 border-slate-600 rounded-r-md`}>2x</button>
                            </div>
                            <section id="keno-difficulty">
                                <label htmlFor='difficulty' className='text-md dark:text-white font-medium mb-2'>Difficulty</label>
                                <select
                                    id="keno-difficulty-select"
                                    name={'difficulty'}
                                    onChange={form.handleChange}
                                    value={form.values.difficulty}
                                    disabled={form.isSubmitting}
                                    className="w-full py-2 appearance-none rounded-md border-2 border-slate-600 bg-slate-900 px-3 text-white font-medium">
                                    <option value={1}>Classic</option>
                                    <option value={2}>Low</option>
                                    <option value={3}>Medium</option>
                                    <option value={4}>High</option>
                                </select>
                            </section>
                            <section id={`play-settings`} className={`space-y-3 mt-4`}>
                                <button
                                    type="button"
                                    onClick={autoPick}
                                    className={`shadow-sm shadow-slate-900 px-5 py-2 w-full bg-slate-700 hover:bg-slate-600 rounded-md text-sm font-medium text-white`}>
                                    Auto pick
                                </button>
                                <button
                                    type="reset"
                                    onClick={clear}
                                    className={`shadow-sm shadow-slate-900 px-5 py-2 w-full bg-slate-700 hover:bg-slate-600 rounded-md text-sm font-medium text-white`}>
                                    Reset
                                </button>
                                <section id="play-button" className="py-4">
                                    <button
                                        type="submit"
                                        disabled={form.isSubmitting}
                                        className={`px-5 py-3 w-full bg-green-500 hover:bg-green-400 rounded-md font-medium text-white text-sm`}>
                                        Play
                                    </button>
                                </section>
                            </section>
                        </Tab.Panel>
                        <Tab.Panel>

                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </>
    )

    const getPayoutSection = useCallback((): JSX.Element => {
        if (form.values.selections.length === 0) {
            return (
                <>
                    <section id={``} className="container-ez rounded-md mt-4 bg-black py-4 w-full">
                        <p className="sm:text-sm text-center font-medium text-white">Select up to 10 numbers to play</p>
                    </section>
                </>
            )
        } else {
            const numSelections = form.values.selections.length as KenoSelectionCount;
            const difficulty = form.values.difficulty as KenoDifficulty;
            const multipliers: any = getDifficultyMultipliers(difficulty, numSelections);
            
            return (
                <>
                    <article className="space-y-4 py-3">
                        <section id={``} className="container-ez rounded-md mt-4 bg-slate-800 py-4 w-full flex justify-evenly">
                            {Object.keys(multipliers).map((i, idx) => (
                                <>
                                    <p className="font-medium text-md text-white">
                                        {(multipliers[i] as number).toFixed(2)}x
                                    </p>
                                </>
                            ))}
                        </section>
                        <section id={``} className="container-ez rounded-md mt-4 bg-slate-800 py-4 w-full flex justify-evenly">
                            {(new Array(numSelections + 1).fill(null)).map((_, idx) => (
                                <>
                                    <p className="font-medium text-md text-white">{idx}</p>
                                </>
                            ))}
                        </section>
                    </article>
                </>
            )
        }

    }, [form.values.selections, form.values.difficulty])

    return (
        <>
            <Helmet>
                <title>Keno | Mocash</title>
            </Helmet>
            <main className="bg-slate-800 2xl:w-2/5 mx-auto">
                <div className=" bg-slate-900 grid md:grid-cols-12 gap-x-5 gap-y-4">
                    <div className="hidden md:block md:col-span-3 bg-slate-800">
                        {KenoSettings}
                    </div>
                    <div className="col-span-12 md:col-span-9 p-5">
                        <div className={`grid grid-cols-8 gap-x-4 gap-y-4`}>
                            {items.map((i, idx) => (
                                <>
                                    {form.values.selections.includes(idx + 1) ? (
                                        <>
                                            <div key={idx} id={`keno-ball-${idx + 1}-selected`} onClick={() => deselectNumber(idx + 1)} className="aspect-square shadow-2xl cursor-pointer col-span-1 p-5 flex rounded-md bg-emerald-500 border-b-4 border-emerald-600">
                                                <article className="w-full flex justify-center">
                                                    <div id={`keno-item-container`}>
                                                        <div id="keno-item-dummy"></div>
                                                        <div id={`keno-item-element`} className="w-full h-full m-auto flex justify-center">
                                                            <div className="m-auto">
                                                                <p className="font-medium text-sm md:text-md dark:text-white text-md md:text-2xl text-slate-900">
                                                                    {idx + 1}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <button disabled={form.values.selections.length === 10} key={idx} id={`keno-ball-${idx + 1}`} onClick={() => selectNumber(idx + 1)} className={`btn aspect-square shadow-2xl cursor-pointer col-span-1 p-5 flex rounded-md bg-slate-700 border-b-4 border-slate-600 ${form.values.selections.length === 10 && 'opacity-60'}`}>
                                                <article className="w-full flex justify-center">
                                                    <div id={`keno-item-container`}>
                                                        <div id="keno-item-dummy"></div>
                                                        <div id={`keno-item-element`} className="h-full w-full m-auto flex justify-center">
                                                            <div className="m-auto">
                                                                <p className="font-medium text-sm md:text-md md:text-2xl text-white">{idx + 1}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </button>
                                        </>
                                    )}
                                </>
                            ))}
                        </div>
                        {getPayoutSection()}
                    </div>
                </div>
                <section id={`keno-mobile`} className="block md:hidden rounded-md">
                    {KenoSettings}
                </section>
            </main >
        </>
    )
}

export default withAccesstoken(KenoGame);