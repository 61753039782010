

interface MinesGameProps { };

const MinesGame = (props: MinesGameProps): JSX.Element => {

    

    return (
        <>
            <main className="bg-slate-900 w-screen min-h-screen">
                <section data-testid="game" className="grid grid-cols-12">
                    <div className="col-span-3"></div>
                    <div className="col-span-9">
                        <div className="grid grid-cols-5 gap-x-3"></div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default MinesGame;