import Logo from '../../components/Logo';
import { RockPaperScissorsImages } from './Assets';
import { Helmet } from 'react-helmet';
import { useFormik, FormikProvider } from 'formik';
import PlayAmount from '../../components/PlayAmount';

interface RockPaperScissorsGameProps { };

const RockPaperScissorsGame = (props: RockPaperScissorsGameProps): JSX.Element => {

    const formik = useFormik<{ amount: number; choice: string; }>({
        initialValues: {
            amount: 10,
            choice: `Rock`
        },
        onSubmit: async (values, { setSubmitting }) => {
            alert(JSON.stringify(values))
        }
    })



    const actions: Array<{ title: string; action: () => void; }> = [
        {
            title: `Rock`,
            action: () => {
                formik.setFieldValue(`choice`, `Rock`)
            }
        },
        {
            title: `Paper`,
            action: () => {
                formik.setFieldValue(`choice`, `Paper`)
            }
        },
        {
            title: `Scissors`,
            action: () => {
                formik.setFieldValue(`choice`, `Scissors`)
            }
        }
    ]

    return (
        <>
            <Helmet>
                <title>Rock, Paper, Scissors | Droplime</title>
            </Helmet>
            <main className='min-h-screen bg-slate-900'>
                <div className='grid grid-cols-12'>
                    <div className='col-span-4 bg-slate-800 p-4'>
                        <div className='flex justify-center'>
                            <Logo />
                        </div>

                        <FormikProvider value={formik}>
                            <PlayAmount />
                        </FormikProvider>
                        <div className='flex justify-evenly gap-x-3 py-2'>
                            {actions.map((i, idx) => (
                                <>
                                    <button key={idx} onClick={i.action} className={`w-full font-medium px-3 py-2 ${formik.values.choice === i.title ? 'bg-lime-500' : 'bg-slate-700 hover:bg-slate-900'} text-white rounded-md`}>
                                        {i.title}
                                    </button>
                                </>
                            ))}
                        </div>
                        <section id={`play-settings`} className={`space-y-3 mt-4`}>
                            <button
                                onClick={() => formik.submitForm()}
                                disabled={formik.isSubmitting}
                                className={`px-5 py-2 w-full bg-lime-500 hover:bg-lime-600 rounded-sm font-medium text-white`}>Play</button>
                        </section>
                    </div>
                    <div className='col-span-8'>
                        <div className='h-96 flex'>
                            <div className='m-auto'>
                                <div className='grid grid-cols-2'>
                                    <div className='col-span-1'>
                                        <article key={`user-selected`} className='m-auto'>
                                            <img key={`powvpiwn'nbn`} src={RockPaperScissorsImages[formik.values.choice.toLowerCase()] as string} className={`reflect-x`} />
                                        </article>
                                    </div>
                                    <div className='col-span-1'>
                                        <article key={`rps-system-selected`} className='m-auto'>
                                            <img key={`dncinvwoniv`} src={RockPaperScissorsImages['scissors'] as string} />
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default RockPaperScissorsGame;