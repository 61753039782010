import { ChangeEvent, useState } from "react";
import { Tab } from '@headlessui/react'
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import Logo from "../../components/Logo";
import { Howl, Howler } from 'howler';
import { useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import PlayAmount from "../../components/PlayAmount";


interface LimboGameProps { };

const LimboGame = (props: LimboGameProps): JSX.Element => {

    const [result, setResult] = useState<number>(1);

    const form = useFormik<{ amount: number; win_chance: number; multiplier: number; autoplay_count: number; }>({
        initialValues: {
            amount: 10,
            win_chance: 49.5,
            multiplier: 2,
            autoplay_count: 10
        },
        onSubmit: async (values, { setSubmitting }) => {

        }
    })

    const halvePlayAmount = () => {
        const newPlayAmount = form.values.amount / 2
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    const doublePlayAmount = () => {
        const newPlayAmount = form.values.amount * 2
        // todo add balance
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    useEffect(() => {
        const winChanceInput = document.getElementById(`win_chance_input`) as HTMLInputElement;
        const multiplierInput = document.getElementById('multiplier_input') as HTMLInputElement;

        winChanceInput.addEventListener(`change`, (ev) => {
            const winChanceValue = (ev.target as any).value;
            const newMultiplier = ((100 / winChanceValue) + 0.5)
            form.setFieldValue(`multiplier`, newMultiplier)
        })
        multiplierInput.addEventListener(`change`, (ev) => {
            const multiplierValue = (ev.target as any).value;
            const newWinChance = 100 / multiplierValue - 0.5;
            form.setFieldValue(`win_chance`, newWinChance)
        })

        return () => {
            winChanceInput.removeEventListener(`change`, () => { });
            multiplierInput.removeEventListener(`change`, () => { });
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Limbo | Droplime</title>
            </Helmet>
            <main className={`min-h-screen mx-auto bg-slate-900`}>
                <div className={`grid md:grid-cols-12 gap-x-3 bg-slate-900`}>
                    <div className={`hidden md:block md:col-span-4 bg-slate-800 p-4`}>
                        <div className="flex justify-center pb-2">
                            <Logo />
                        </div>
                        <div className="w-full">
                            <Tab.Group
                                onChange={(index) => {
                                    form.setFieldValue(`is_autoplay`, index === 1);
                                }}
                            >
                                <Tab.List className={`w-full flex justify-evenly focus:outline-none`}>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            /* Use the `selected` state to conditionally style the selected tab. */
                                            <button
                                                className={
                                                    `${selected ? 'bg-lime-500 text-white' : 'bg-white text-lime-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-100'}`
                                                }
                                            >
                                                Manual
                                            </button>
                                        )}
                                    </Tab>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            <button
                                                className={
                                                    `${selected ? 'bg-lime-500 text-white' : 'bg-white text-lime-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-100'}`
                                                }
                                            >
                                                Auto
                                            </button>
                                        )}
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className={`py-5`}>
                                    <Tab.Panel className={`w-full`}>
                                        <label htmlFor='amount' className='text-sm font-medium mb-2 text-white'>Play amount</label>
                                        <div className={`flex justify-start my-3`}>
                                            <input
                                                id={`amount`}
                                                onChange={form.handleChange}
                                                value={form.values.amount}
                                                disabled={form.isSubmitting}
                                                name={`amount`}
                                                type={`number`}
                                                min={1}
                                                step={10}
                                                placeholder='amount'
                                                className={`flex-8 w-full rounded-l-sm border-l border-t border-b py-2 px-3 focus:outline-none focus:ring-1 focus:border-lime-500`} />
                                            <button
                                                onClick={halvePlayAmount}
                                                className={`flex-1 px-3 bg-slate-50 hover:bg-lime-500 text-xs font-medium border`}>½</button>
                                            <button
                                                onClick={doublePlayAmount}
                                                className={`flex-1 px-3 bg-slate-50 hover:bg-lime-500 text-xs font-medium border-r border-t border-b rounded-r-sm`}>2x</button>
                                        </div>
                                        <section id={`play-settings`} className={`space-y-3 mt-4`}>
                                            <button
                                                className={`px-5 py-2 w-full bg-lime-500 hover:bg-lime-600 rounded-sm font-medium text-white`}>Play</button>
                                        </section>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <FormikProvider value={form}>
                                            <section className={`space-y-4`}>
                                                <div>
                                                    <label htmlFor='autoplay_count' className='text-sm font-medium mb-2 text-white'>Number of plays</label>
                                                    <input
                                                        name={`autoplay_count`}
                                                        value={form.values.autoplay_count}
                                                        onChange={form.handleChange}
                                                        disabled={form.isSubmitting}
                                                        onBlur={form.handleBlur}
                                                        className={`flex-8 w-full rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-lime-500`}
                                                    />
                                                </div>
                                                <PlayAmount />
                                                <button className={`px-5 py-2 w-full bg-lime-500 hover:bg-lime-600 rounded-sm font-medium text-white`}>Start autoplay</button>
                                            </section>
                                        </FormikProvider>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                    <div className={`col-span-12 md:col-span-8 px-4`}>
                        <div className="h-48 md:h-72 flex">
                            <div className="m-auto">
                                <p className={`text-8xl font-semibold text-white`}>{result.toFixed(2)}x</p>
                            </div>
                        </div>
                        <section id={`multiplier-container`} className={`w-full flex justify-around gap-x-3 px-3 py-2 rounded-sm bg-slate-700`}>
                            <div className="w-full">
                                <label htmlFor='multiplier' className='text-sm font-medium mb-2 text-white'>Target multiplier</label>
                                <input
                                    id={`multiplier_input`}
                                    name={`multiplier`}
                                    value={form.values.multiplier}
                                    onChange={form.handleChange}
                                    disabled={form.isSubmitting}
                                    min={2}
                                    max={1998}
                                    className={`w-full bg-slate-900 rounded-sm text-white font-medium py-2 px-3 focus:outline-none focus:ring-1 focus:border-lime-500`}
                                />
                            </div>
                            <div className="w-full">
                                <label htmlFor='win_chance' className='text-sm font-medium mb-2 text-white'>Win chance</label>
                                <input
                                    id={`win_chance_input`}
                                    name={`win_chance`}
                                    value={form.values.win_chance}
                                    onChange={form.handleChange}
                                    disabled={form.isSubmitting}
                                    className={`w-full bg-slate-900 rounded-sm text-white font-medium py-2 px-3 focus:outline-none focus:ring-1 focus:border-lime-500`}
                                />
                            </div>
                        </section>
                    </div>
                    <div className={`block md:hidden col-span-12 md:col-span-0 m-4 rounded-sm`}>
                        <div className="w-full p-4 bg-slate-800">
                            <Tab.Group>
                                <Tab.List className={`w-full flex justify-evenly focus:outline-none`}>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            /* Use the `selected` state to conditionally style the selected tab. */
                                            <button
                                                className={
                                                    `${selected ? 'bg-lime-500 text-white' : 'bg-white text-lime-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-100'}`
                                                }
                                            >
                                                Manual
                                            </button>
                                        )}
                                    </Tab>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            <button
                                                className={
                                                    `${selected ? 'bg-lime-500 text-white' : 'bg-white text-lime-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-100'}`
                                                }
                                            >
                                                Auto
                                            </button>
                                        )}
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className={`py-5`}>
                                    <Tab.Panel className={`w-full`}>
                                        <label htmlFor='amount' className='text-sm font-medium mb-2 text-white'>Play amount</label>
                                        <div className={`flex justify-start my-3`}>
                                            <input
                                                onChange={form.handleChange}
                                                value={form.values.amount}
                                                disabled={form.isSubmitting}
                                                name={`amount`}
                                                type={`number`}
                                                min={1}
                                                step={10}
                                                placeholder='amount'
                                                className={`flex-8 w-full rounded-l-sm border-l border-t border-b py-2 px-3 focus:outline-none focus:ring-1 focus:border-lime-500`} />
                                            <button
                                                onClick={halvePlayAmount}
                                                className={`flex-1 px-3 bg-slate-50 hover:bg-lime-500 text-xs font-medium border`}>½</button>
                                            <button
                                                onClick={doublePlayAmount}
                                                className={`flex-1 px-3 bg-slate-50 hover:bg-lime-500 text-xs font-medium border-r border-t border-b rounded-r-sm`}>2x</button>
                                        </div>
                                        <section id={`play-settings`} className={`space-y-3 mt-4`}>
                                            <button
                                                className={`px-5 py-2 w-full bg-lime-500 hover:bg-lime-600 rounded-sm font-medium text-white`}>Play</button>
                                        </section>
                                    </Tab.Panel>
                                    <Tab.Panel>

                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default LimboGame;