import Matter, { World } from 'matter-js';
import { useEffect, useRef, useState } from 'react';
import { Howl } from 'howler';
import chroma from "chroma-js";
import { Helmet } from 'react-helmet';
import Logo from '../../components/Logo';
import { FormikProvider, useFormik } from "formik";
import PlayAmount from "../../components/PlayAmount";
import { Tab } from '@headlessui/react'

interface PlinkoGameProps { };

const PlinkoGame = (props: PlinkoGameProps): JSX.Element => {
    const gameRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialised, setInitialised] = useState<boolean>(false);
    const [renderer, setRenderer] = useState<Matter.Render>()
    const { Engine, Events, Render, Runner, Bodies, Composite } = Matter;
    const [minDropX, setMinDropX] = useState<number>();
    const [maxDropX, setMaxDropX] = useState<number>();
    const [translateX, setTranslateX] = useState<number>();
    const [translateY, setTranslateY] = useState<number>();

    const scene = useRef<HTMLDivElement>(null);
    const engine = useRef(Engine.create());

    const form = useFormik<{ is_autoplay: boolean; amount: number; autoplay_count?: number; }>({
        initialValues: {
            amount: 10,
            is_autoplay: false,
            autoplay_count: 10
        },
        onSubmit: async (values, { setSubmitting }) => {

        }
    })

    const sound = new Howl({
        src: ['/sounds/chime.ogg'],
    });

    const groundCategory = 0x0003, ballCategory = 0x0004, gridCategory = 0x0005;

    useEffect(() => {
        if (!engine.current) return;
        if (!scene.current) return;
        if (!gameRef.current) return;
        engine.current.gravity.scale = 0.0005
        const render = Render.create({
            element: scene.current,
            engine: engine.current,
            options: {
                // showStats: true,
                width: gameRef.current.clientWidth,
                height: gameRef.current.clientHeight,
                background: '#0f172a',
                wireframes: false, // <-- important
                showDebug: process.env.NODE_ENV === 'development',
            }
        });
        setRenderer(render);
        let bodies: Array<Matter.Body> = []
        const rows = 10;
        const totalRows = rows + 3;
        const xOffset = 30;
        const yOffset = 35;
        let offset;
        // render the triangular grid
        for (let row = 3; row < totalRows; row++) {
            const lastRowDifference = totalRows - row;
            offset = lastRowDifference / 2;
            for (let col = offset; col < row + offset; col++) {
                const x = (col * xOffset) + (((render.options.width as number) / 2) - (xOffset * totalRows) / 2);
                const y = row * 35 + (render.options.height as number) / totalRows;
                bodies.push(Bodies.circle(
                    x, y, 2,
                    {
                        label: 'grid',
                        isStatic: true,
                        density: 20,
                        friction: 1,
                        collisionFilter: {
                            category: gridCategory,
                            mask: ballCategory
                        },
                        render: {
                            fillStyle: 'white',
                            strokeStyle: 'white',
                            lineWidth: 6,
                        }
                    }))
            }
        }
        // set the bounds that the ball can drop in
        setMinDropX((((totalRows - 3) / 2) * xOffset) + ((render.canvas.width / 2) - (xOffset * totalRows) / 2));
        setMaxDropX(((((totalRows - 3) / 2) + 2) * xOffset) + ((render.canvas.width / 2) - (xOffset * totalRows) / 2));

        // render the outcomes
        const scale = chroma.scale(['#84cc16', '#06b6d4', '#84cc16']);
        const wh = (bodies[1].position.x - bodies[0].position.x) - (((bodies[0]).circleRadius as number) * 4);
        for (let i = 1; i < totalRows - 1; i++) {
            const x = (i * xOffset) + ((render.canvas.width / 2) - (xOffset * totalRows) / 2);
            const y = ((totalRows + 1.2) * yOffset) + ((bodies[0].circleRadius as number)) + wh;
            bodies.push(Bodies.rectangle(
                x,
                y,
                wh,
                wh,
                {
                    label: 'ground',
                    isStatic: true,
                    render: {
                        fillStyle: scale(i / (totalRows - 1)).hex(),
                        strokeStyle: scale(i / (totalRows - 1)).hex(),
                        lineWidth: 6
                    }
                }))
        }

        setTranslateX(((render.canvas.width / 2) - (xOffset * totalRows) / 2));
        setTranslateY(((totalRows + 1) * yOffset) + (bodies[0].circleRadius as number) + wh);

        Composite.add(engine.current.world, bodies);

        Render.run(render);
        const runner = Runner.create();

        // remove the ball when it lands
        Events.on(engine.current, `collisionStart`, (e: any) => {
            const pair = e.pairs.at(0) as Matter.Pair;
            const isGroundA = pair.bodyA.label === 'ground';
            const isGroundB = pair.bodyB.label === 'ground';
            const isBallA = pair.bodyA.label === 'ball';
            const isBallB = pair.bodyB.label === 'ball';
            // destroy the ball if collision with ground detected
            if ((isGroundA || isGroundB) && (isBallA || isBallB)) {
                sound.play();
                if (isBallA) {
                    Composite.remove(engine.current.world, pair.bodyA)
                } else {
                    Composite.remove(engine.current.world, pair.bodyB)
                }
            }
        })


        Runner.run(runner, engine.current);

        /**
         * scale the graphics about the point that the rendering occurs
         */
        if (translateX && translateY) {
            Composite.scale(engine.current.world, 0.8, 0.8, { x: translateX, y: translateY });
        }


        window.addEventListener('resize', () => {
            render.bounds.max.x = window.innerWidth;
            render.bounds.max.y = window.innerHeight;
            render.options.width = window.innerWidth;
            render.options.height = window.innerHeight;
            render.canvas.width = window.innerWidth;
            render.canvas.height = window.innerHeight;
            Matter.Render.setPixelRatio(render, window.devicePixelRatio);
        });


        return () => {
            Render.stop(render)
            World.clear(engine.current.world, false)
            Engine.clear(engine.current)
            render.canvas.remove()
            // @ts-ignore
            render.canvas = null
            // @ts-ignore
            render.context = null
            render.textures = {}
            window.removeEventListener(`resize`, () => null)
        }
    }, [])

    const onClick = () => {
        if (!engine.current) return;
        if (!renderer) return;
        if (!minDropX || !maxDropX) return;
        if (!translateX || !translateY) return;
        setIsLoading(true);
        setTimeout(() => {
            const ball = Bodies.circle(Math.round((Math.random() * (maxDropX - minDropX)) + minDropX), 100, 8, {
                label: 'ball',
                friction: 1,
                density: 3,
                restitution: 1,
                collisionFilter: {
                    category: ballCategory,
                    group: -1,
                },
                render: {
                    fillStyle: 'lime',
                    strokeStyle: 'lime',
                    lineWidth: 6,
                }
            });
            // Composite.scale(ball, 0.8, 0.8, { x: translateX, y: translateY });
            Composite.add(engine.current.world, ball)
            setIsLoading(false);
        }, 500)
    }

    const halvePlayAmount = () => {
        const newPlayAmount = form.values.amount / 2
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    const doublePlayAmount = () => {
        const newPlayAmount = form.values.amount * 2
        // todo add balance
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    return (
        <>
            <Helmet>
                <title>Plinko | Droplime</title>
            </Helmet>
            <main className={`min-h-screen`}>
                <section className={`grid grid-cols-12`}>
                    <div className={`hidden md:block col-span-0 md:col-span-4 bg-slate-800 p-4`}>
                        <div className="flex justify-center pb-2">
                            <Logo />
                        </div>
                        <div className="w-full">
                            <Tab.Group
                                onChange={(index) => {
                                    form.setFieldValue(`is_autoplay`, index === 1);
                                }}
                            >
                                <Tab.List className={`w-full flex justify-evenly focus:outline-none`}>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            /* Use the `selected` state to conditionally style the selected tab. */
                                            <button
                                                className={
                                                    `${selected ? 'bg-emerald-500 text-white' : 'bg-slate-700 text-emerald-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-900'} rounded-l-md`
                                                }
                                            >
                                                Manual
                                            </button>
                                        )}
                                    </Tab>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            <button
                                                className={
                                                    `${selected ? 'bg-emerald-500 text-white' : 'bg-slate-700 text-emerald-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-900'} rounded-r-md`
                                                }
                                            >
                                                Auto
                                            </button>
                                        )}
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className={`py-5`}>
                                    <Tab.Panel className={`w-full`}>
                                        <label htmlFor='amount' className='text-sm font-medium mb-2 text-white'>Play amount</label>
                                        <div className={`flex justify-start my-3`}>
                                            <input
                                                id={`amount`}
                                                onChange={form.handleChange}
                                                value={form.values.amount}
                                                disabled={form.isSubmitting}
                                                name={`amount`}
                                                type={`number`}
                                                min={1}
                                                step={10}
                                                placeholder='amount'
                                                className={`flex-8 w-full bg-slate-700 rounded-l-sm border-l border-t border-b py-2 px-3 focus:outline-none border-none focus:border focus:ring-0 focus:border-emerald-500`} />
                                            <button
                                                onClick={halvePlayAmount}
                                                className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border`}>½</button>
                                            <button
                                                onClick={doublePlayAmount}
                                                className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border-r border-t border-b rounded-r-sm`}>2x</button>
                                        </div>
                                        <section id={`play-settings`} className={`space-y-3 mt-4`}>
                                            <button
                                                onClick={onClick}
                                                className={`px-5 py-4 w-full bg-emerald-500 hover:bg-emerald-600 rounded-sm font-medium text-white`}>Play</button>
                                        </section>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <FormikProvider value={form}>
                                            <section className={`space-y-4`}>
                                                <div>
                                                    <label htmlFor='autoplay_count' className='text-sm font-medium mb-2 text-white'>Number of plays</label>
                                                    <input
                                                        name={`autoplay_count`}
                                                        value={form.values.autoplay_count}
                                                        onChange={form.handleChange}
                                                        disabled={form.isSubmitting}
                                                        onBlur={form.handleBlur}
                                                        className={`flex-8 w-full rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`}
                                                    />
                                                </div>
                                                <PlayAmount />
                                                <button className={`px-5 py-4 w-full bg-emerald-500 hover:bg-emerald-600 rounded-sm font-medium text-white`}>Start autoplay</button>
                                            </section>
                                        </FormikProvider>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                    <div className={`col-span-12 md:col-span-8 bg-slate-900 h-screen w-full`} ref={gameRef} >
                        <div ref={scene} />
                    </div>
                    <div className={`block md:hidden col-span-12 md:col-span-0 bg-slate-800 p-4`}>
                        <div className="flex justify-center pb-2">
                            <Logo />
                        </div>
                        <div className="w-full">
                            <Tab.Group
                                onChange={(index) => {
                                    form.setFieldValue(`is_autoplay`, index === 1);
                                }}
                            >
                                <Tab.List className={`w-full flex justify-evenly focus:outline-none`}>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            /* Use the `selected` state to conditionally style the selected tab. */
                                            <button
                                                className={
                                                    `${selected ? 'bg-emerald-500 text-white' : 'bg-white text-emerald-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-100'}`
                                                }
                                            >
                                                Manual
                                            </button>
                                        )}
                                    </Tab>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            <button
                                                className={
                                                    `${selected ? 'bg-emerald-500 text-white' : 'bg-white text-emerald-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-100'}`
                                                }
                                            >
                                                Auto
                                            </button>
                                        )}
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className={`py-5`}>
                                    <Tab.Panel className={`w-full`}>
                                        <label htmlFor='amount' className='text-sm font-medium mb-2 text-white'>Play amount</label>
                                        <div className={`flex justify-start my-3`}>
                                            <input
                                                id={`amount`}
                                                onChange={form.handleChange}
                                                value={form.values.amount}
                                                disabled={form.isSubmitting}
                                                name={`amount`}
                                                type={`number`}
                                                min={1}
                                                step={10}
                                                placeholder='amount'
                                                className={`flex-8 w-full rounded-l-sm border-l border-t border-b py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                            <button
                                                onClick={halvePlayAmount}
                                                className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border`}>½</button>
                                            <button
                                                onClick={doublePlayAmount}
                                                className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border-r border-t border-b rounded-r-sm`}>2x</button>
                                        </div>
                                        <section id={`play-settings`} className={`space-y-3 mt-4`}>
                                            <button
                                                onClick={onClick}
                                                className={`px-5 py-2 w-full bg-emerald-500 hover:bg-emerald-600 rounded-sm font-medium text-white`}>Play</button>
                                        </section>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <FormikProvider value={form}>
                                            <section className={`space-y-4`}>
                                                <div>
                                                    <label htmlFor='autoplay_count' className='text-sm font-medium mb-2 text-white'>Number of plays</label>
                                                    <input
                                                        name={`autoplay_count`}
                                                        value={form.values.autoplay_count}
                                                        onChange={form.handleChange}
                                                        disabled={form.isSubmitting}
                                                        onBlur={form.handleBlur}
                                                        className={`flex-8 w-full rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`}
                                                    />
                                                </div>
                                                <PlayAmount />
                                                <button className={`px-5 py-2 w-full bg-emerald-500 hover:bg-emerald-600 rounded-sm font-medium text-white`}>Start autoplay</button>
                                            </section>
                                        </FormikProvider>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default PlinkoGame;