import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import { Tab } from '@headlessui/react';
import { useFormik } from 'formik';
import { rouletteTable } from '../../lib/roulette';



interface RouletteGameProps { };

const RouletteGame = (props: RouletteGameProps): JSX.Element => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [animationHandle, setAnimationHandle] = useState<number>();

    const form = useFormik({
        initialValues: {
            amount: 10,
            wagers: [],
            remaining_plays: 0,
        },
        onSubmit: async () => { }
    })

    const animate = () => {

        /**
         * the orbit of the moon is used for this:
         *  
         * https://developer.mozilla.org/en-US/play */
        if (!canvasRef.current) return;
        const ctx = canvasRef.current?.getContext('2d');
        if (!ctx) return;
        ctx.globalCompositeOperation = `destination-over`;
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        const time = new Date();
        ctx.save();
        ctx.translate(canvasRef.current.width / 2, canvasRef.current.height / 2);
        ctx.rotate(
            ((2 * Math.PI) / 6) * time.getSeconds() +
            ((2 * Math.PI) / 6000) * time.getMilliseconds(),
        );
        ctx.translate(0, 145);
        ctx.arc(0, 0, 4, 0, 2 * Math.PI);
        ctx.restore();
        ctx.fillStyle = "#ff0000";
        ctx.strokeStyle = "#FF0000";
        ctx.fill();
        ctx.beginPath();

        ctx.stroke();

        setAnimationHandle(requestAnimationFrame(animate))
    }

    const spin = () => {
        if (!canvasRef.current) return;
        const ctx = canvasRef.current?.getContext('2d');
    }

    const play = async () => {

    }

    /**
     * cancels the roulette canvas ball animation
     */
    const stopRouletteAnimation = () => {
        if (animationHandle !== undefined) {
            cancelAnimationFrame(animationHandle);
        }
    }

    useEffect(() => {
        animate()
    }, [])

    const halvePlayAmount = () => {
        const newPlayAmount = form.values.amount / 2
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    const doublePlayAmount = () => {
        const newPlayAmount = form.values.amount * 2
        // todo add balance
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    return (
        <>
            <main className='w-full px-56 dark:bg-slate-800'>
                <div className='grid grid-cols-12'>
                    <div className='col-span-3'>
                        <section className='dark:bg-slate-900 rounded-b-md'>
                            <Tab.Group
                                onChange={(index) => {
                                    form.setFieldValue(`is_autoplay`, index === 1);
                                }}
                            >
                                <Tab.List className={`flex justify-evenly focus:outline-none`}>
                                    <Tab className={`w-full focus:outline-none rounded-t-md`}>
                                        {({ selected }) => (
                                            /* Use the `selected` state to conditionally style the selected tab. */
                                            <button
                                                className={
                                                    `${selected ? 'bg-emerald-500 text-white' : 'bg-slate-600 text-white'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-700'} rounded-tl-md`
                                                }
                                            >
                                                Manual
                                            </button>
                                        )}
                                    </Tab>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            <button
                                                className={
                                                    `${selected ? 'bg-emerald-500 text-white' : 'bg-slate-600 text-white'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-700'} rounded-tr-md`
                                                }
                                            >
                                                Auto
                                            </button>
                                        )}
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className={`p-5 bg-slate-900 rounded-b-md`}>
                                    <Tab.Panel>
                                        <article className='rounded-sm'>
                                            <div className={`grid md:grid-cols-2 gap-x-5`}>
                                                <div className={`col-span-2`}>
                                                    <label htmlFor='amount' className='text-sm font-medium mb-2 text-white'>Play amount</label>
                                                    <div className={`flex justify-start my-3`}>
                                                        <input
                                                            onChange={form.handleChange}
                                                            value={form.values.amount}
                                                            disabled={form.isSubmitting}
                                                            name={`amount`}
                                                            type={`number`}
                                                            min={1}
                                                            step={10}
                                                            placeholder='amount'
                                                            className={`flex-8 text-center w-full rounded-l-sm border-l border-t border-b py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                                        <button
                                                            onClick={halvePlayAmount}
                                                            className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border`}>½</button>
                                                        <button
                                                            onClick={doublePlayAmount}
                                                            className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border-r border-t border-b rounded-r-sm`}>2x</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <button className={`mt-5 px-5 py-4 w-full bg-emerald-500 hover:bg-emerald-600 rounded-sm font-medium text-white`}>Play</button>
                                        </article>
                                    </Tab.Panel>
                                    <Tab.Panel className={'rounded-b-md'}>
                                        <div className={`grid gap-x-5`}>
                                            <div className={`col-span-1 container`}>
                                                <label htmlFor='amount' className='text-sm font-medium mb-2 text-white'>Play amount</label>
                                                <div className={`flex justify-start my-3`}>
                                                    <input
                                                        onChange={form.handleChange}
                                                        value={form.values.amount}
                                                        disabled={form.isSubmitting}
                                                        name={`amount`}
                                                        type={`number`}
                                                        min={0.01}
                                                        placeholder='amount'
                                                        className={`flex-8 text-center w-full rounded-l-sm border-l border-t border-b py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                                    <button
                                                        onClick={halvePlayAmount}
                                                        className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border`}>½</button>
                                                    <button
                                                        onClick={doublePlayAmount}
                                                        className={`flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium border-r border-t border-b rounded-r-sm`}>2x</button>
                                                </div>
                                            </div>
                                            <div className={`col-span-1 container`}>
                                                <label htmlFor='remaining_plays' className='text-sm font-medium mb-2 text-white'>Number of plays</label>
                                                <input
                                                    onChange={form.handleChange}
                                                    value={form.values.remaining_plays}
                                                    disabled={form.isSubmitting}
                                                    name={`remaining_plays`}
                                                    placeholder={`Select`}
                                                    className={`my-3 w-full text-center rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                                            </div>
                                        </div>
                                        <button className={`px-5 py-4 w-full bg-emerald-500 hover:bg-emerald-600 rounded-sm font-medium text-white`}>Auto play</button>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </section>
                    </div>
                    <div className='col-span-9'>
                        <section id={"roulette__wheel"} className='flex justify-center pb-4 rotating'>
                            <canvas
                                ref={canvasRef}
                                width={300}
                                height={300}
                                style={{
                                    backgroundImage: `url("/roulette/wheel.png")`,
                                    backgroundPosition: `center`,
                                    backgroundSize: '270px 270px'
                                }}
                                className={styles.roulette__wheel__rotate}
                            />
                        </section>
                        <section id="roulette__wrapper" className="p-3">
                            <section id="roulette__table__wrapper" className='grid grid-cols-12 gap-3'>
                                <div className='col-span-1'>
                                    <div className='rounded-lg w-full h-full bg-slate-900'></div>
                                </div>
                                <div className='col-span-11'>
                                    <section id="roulette__table" className='grid grid-cols-13 gap-3'>
                                        {rouletteTable.map((i, idx) => (
                                            <>
                                                <article
                                                    key={idx}
                                                    id={"roulette__number"}
                                                    className={'col-span-1 aspect-square cursor-pointer bg-slate-900 hover:bg-slate-700 w-full h-full rounded-lg  text-md font-medium'}>
                                                    <div className='w-full h-full m-auto'>
                                                        <p className='text-white font-medium h-full text-center'>
                                                            <div className='h-full flex flex-col justify-center'>
                                                                {i.value}
                                                            </div>
                                                        </p>
                                                    </div>
                                                </article>
                                            </>
                                        ))}
                                    </section>
                                </div>
                            </section>
                            <section id={'roulette__dozens'} className={`w-full grid grid-cols-3 gap-x-2 pt-3`}>
                                <article className='col-span-1 flex justify-center py-4 bg-gray-900 rounded-lg text-white'>
                                    1-12
                                </article>
                                <article className='col-span-1 flex justify-center py-4 bg-gray-900 rounded-lg text-white'>
                                    13-24
                                </article>
                                <article className='col-span-1 flex justify-center py-4 bg-gray-900 rounded-lg text-white'>
                                    25-36
                                </article>
                            </section>
                            <section id={'roulette__side__bets'} className={`w-full grid grid-cols-6 gap-x-2 pt-3`}>
                                <div className='col-span-1 flex justify-center py-4 bg-gray-900 rounded-lg text-white'>
                                    1-18
                                </div>
                                <div className='col-span-1 flex justify-center py-4 bg-gray-900 rounded-lg text-white'>
                                    Even
                                </div>
                                <div className='col-span-1 flex justify-center py-4 bg-gray-900 rounded-lg text-white'>
                                    Red
                                </div>
                                <div className='col-span-1 flex justify-center py-4 bg-gray-900 rounded-lg text-white'>
                                    Black
                                </div>
                                <div className='col-span-1 flex justify-center py-4 rounded-lg bg-slate-900 text-white'>
                                    Odd
                                </div>
                                <div className='col-span-1 cursor-pointer flex justify-center py-4 bg-slate-900 hover:bg-slate-900 text-white rounded-lg'>
                                    19-36
                                </div>
                            </section>
                            <button>Start</button>
                        </section>
                    </div>
                </div>
            </main>
        </>
    )
}

export default RouletteGame;