import { FormikProvider, useFormik } from "formik";
import PlayAmount from "../../components/PlayAmount";
import { useState } from "react";
import { Helmet } from "react-helmet";


interface VideoPokerProps { };

const VideoPoker = (props: VideoPokerProps): JSX.Element => {

    const form = useFormik<{ amount: number; }>({
        initialValues: {
            amount: 10
        },
        onSubmit: async (values, { setSubmitting }) => {

        }
    })

    const items: Array<{ title: string; multiplier: number; }> = [
        {
            title: `Royal Flush`,
            multiplier: 100
        },
        {
            title: `Straight Flush`,
            multiplier: 40
        },
        {
            title: `4 of a Kind`,
            multiplier: 20
        },
        {
            title: `Full House`,
            multiplier: 9
        },
        {
            title: `Flush`,
            multiplier: 6
        },
        {
            title: `Straight`,
            multiplier: 4
        },
        {
            title: `3 of a Kind`,
            multiplier: 3
        },
        {
            title: `2 Pair`,
            multiplier: 2
        },
        {
            title: `Jacks or better`,
            multiplier: 1
        }
    ]

    const [results, setResults] = useState<Array<{ result?: string; hold?: boolean }>>([
        {
            result: undefined,
        },
        {
            result: undefined,
        },
        {
            result: undefined,
        },
        {
            result: undefined,
        },
        {
            result: undefined,
        }
    ])

    const change = (i: number) => {
        setResults((prev) => {
            let newArr = prev.map((item, idx) => {
                if (idx === i) {
                    if (item.result) {
                        return { ...item, hold: !item.hold };
                    }
                    return item;
                } else {
                    return item;
                }
            });
            return newArr;
        })
    }

    return (
        <>
            <Helmet>
                <title>Video Poker | Droplime</title>
            </Helmet>
            <article className="p-10 mx-auto bg-slate-800 min-h-screen">
                <div className={`grid grid-cols-12 gap-x-4`}>
                    <div className={`col-span-4`}>
                        <FormikProvider value={form}>
                            <PlayAmount />
                            <button type={`submit`} className={`px-5 py-4 w-full bg-lime-500 hover:bg-lime-600 rounded-sm font-medium text-white`}>Play game</button>
                        </FormikProvider>
                    </div>
                    <div className={`col-span-8 p-4`}>
                        {items.map((i, idx) => (
                            <>
                                <div key={idx} className="grid grid-cols-12 pb-3">
                                    <div key={idx} className="col-span-4">
                                        <div className="py-1 rounded-l-md bg-slate-700">
                                            <p className="font-medium px-2 text-white">{i.title}</p>
                                        </div>
                                    </div>
                                    <div key={idx} className="col-span-3 my-auto rounded-r-md py-1 bg-slate-200">
                                        <p className="text-center font-medium">{i.multiplier}x</p>
                                    </div>
                                    <div className="col-span-5">
                                        <div className={`flex justify-end my-auto`}>
                                            <p className={`text-slate-200 font-medium`}>{(i.multiplier * form.values.amount).toFixed(2)} 💎</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                        <section id={`deal-card`}>
                            <div className={`flex justify-center py-4`}>
                                <button className={`px-5 py-4 w-full bg-lime-500 hover:bg-lime-600 rounded-sm font-medium text-white`}>Deal</button>
                            </div>
                        </section>
                        <section id={`card-set`} className="mt-6">
                            <div className="flex justify-around gap-x-1">
                                {results.map((i, idx) => (
                                    <>
                                        <article id={`card-${idx}`} onClick={() => change(idx)} key={idx} className={`${i.result && `cursor-pointer`} relative ${i.hold && `bg-lime-500 rounded-xl p-1`}`}>
                                            <img src={i.result || `/cards/card-back.svg`} height={200} width={140} />
                                            {i.hold && (
                                                <>
                                                    <div className="absolute m-auto bottom-0 left-0 right-0 text-center w-min">
                                                        <p className={`text-white text-sm font-medium px-3 py-1 bg-lime-500 rounded-t-sm`}>Hold</p>
                                                    </div>
                                                </>
                                            )}
                                        </article>
                                    </>
                                ))}
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        </>
    )
}

export default VideoPoker;