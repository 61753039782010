export interface SlotProps {
}

const Slot = (props: SlotProps) => {
    return (
        <>
            
        </>
    )
}
export default Slot;