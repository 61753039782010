import { useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import { Helmet } from 'react-helmet';

interface WheelGameProps { };

const WheelGame = (props: WheelGameProps): JSX.Element => {

    const [spinning, setSpinning] = useState<boolean>(false);

    const data = [
        { option: '0', style: { backgroundColor: 'green', textColor: 'black' } },
        { option: '1', style: { backgroundColor: 'white' } },
        { option: '2' },
        { option: '3' },
        { option: '4' },
        { option: '5' },
        { option: '6' },
        { option: '7' },
    ]


    return (
        <>
            <Helmet>
                <title>Wheel | Droplime</title>
            </Helmet>
            <Wheel
                mustStartSpinning={spinning}
                prizeNumber={2}
                data={data}
                backgroundColors={['red', 'black']}
                outerBorderWidth={6}
                textColors={['#ffffff']}
                disableInitialAnimation
                onStopSpinning={() => {
                    setTimeout(() => {
                        setSpinning(false);
                    }, 200)
                }}
            />
            <button onClick={() => setSpinning(true)}>Spin</button>
        </>
    )
}

export default WheelGame;