import { RouterProvider, createBrowserRouter } from "react-router-dom";
import DiceGame from "./games/Dice/DiceGame";
import KenoGame from "./games/Keno/KenoGame";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HiLoGame from "./games/HiLo/HiLoGame";
import MinesGame from "./games/Mines/MinesGame";
import WheelGame from "./games/Wheel/WheelGame";
import VideoPoker from "./games/VideoPoker/VideoPoker";
import PlinkoGame from "./games/Plinko/PlinkoGame";
import LimboGame from "./games/Limbo/LimboGame";
import CoinFlipGame from "./games/CoinFlip/CoinFlipGame";
import BlackjackGame from "./games/Blackjack/BlackjackGame";
import WordleGame from "./games/Wordle/WordleGame";
import RockPaperScissorsGame from "./games/RockPaperScissors/RockPaperScissorsGame";
import RouletteGame from "./games/Roulette/RouletteGame";
import SlideGame from "./games/Slide/SlideGame";
import Slot from "./games/Slot/Slot";
import CrashGame from "./games/Crash/CrashGame";


const router = createBrowserRouter([
  {
    path: `/blackjack`,
    element: <BlackjackGame/>
  },
  {
    path: `/blackjack`,
    element: <BlackjackGame/>
  },
  {
    path: `/coin-flip`,
    element: <CoinFlipGame/>
  },
  {
    path: `/crash`,
    element: <CrashGame/>
  },
  {
    path: `/dice`,
    element: <DiceGame/>
  },
  {
    path: `/hilo`,
    element: <HiLoGame/>
  },
  {
    path: `/keno`,
    element: <KenoGame/>
  },
  {
    path: `/limbo`,
    element: <LimboGame/>
  },
  {
    path: `/mines`,
    element: <MinesGame/>
  },
  {
    path: `/plinko`,
    element: <PlinkoGame/>
  },
  {
    path: `/rock-paper-scissors`,
    element: <RockPaperScissorsGame/>
  },
  {
    path: `/roulette`,
    element: <RouletteGame/>
  },
  {
    path: `/slide`,
    element: <SlideGame/>
  },
  {
    path: `/slot`,
    element: <Slot/>
  },
  {
    path: `/video-poker`,
    element: <VideoPoker/>
  },
  {
    path: `/wheel`,
    element: <WheelGame/>
  },
  {
    path: `/wordle`,
    element: <WordleGame/>
  },
  {
    path: `/roulette`,
    element: <RouletteGame/>
  }
])

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer/>
    </>
  );
}

export default App;
