
/**
 * Injects an access token into a component
 * @param Component wrapped component
 * @returns JSX.Element
 */
export const withAccesstoken = (Component: any) => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token')

    return (props: any) => <Component token={token} {...props} />
}