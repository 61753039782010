

export const getHigherMultiplier = (rank: number) => {
    return (13 / (13 - rank + 1)) * 0.9
}


export const getLowerMultiplier = (rank: number) => {
    return (13 / (rank + 1)) * 0.9
}
