import { FormikProvider, useFormik } from "formik"
import PlayAmount from "../../components/PlayAmount";
import Logo from "../../components/Logo";
import { Helmet } from "react-helmet";


interface BlackjackGameProps { };

const BlackjackGame = (props: BlackjackGameProps): JSX.Element => {

    const form = useFormik<{ amount: number; }>({
        initialValues: {
            amount: 10,
        },
        onSubmit: async (values, { setSubmitting }) => {

        }
    })


    /**
     * take no more cards
     */
    const stand = () => {

    }

    /**
     * increase the play amount by 100% and 
     * take exactly one more card
     */
    const double = () => {

    }

    /**
     * Create two hands from a starting hand where 
     * both cards are the same value
     */
    const split = () => {

    }

    /**
     * draw another card
     */
    const hit = () => { }

    /**
     * forfeit half the play amount and end the round
     */
    const surrender = () => {

    }

    const actions: Array<{ title: string; action: () => void; disabled: boolean; }> = [
        {
            title: `Hit`,
            action: hit,
            disabled: false
        },
        {
            title: `Stand`,
            action: stand,
            disabled: false
        },
        {
            title: `Split`,
            action: split,
            disabled: false
        },
        {
            title: `Double`,
            action: double,
            disabled: false
        }
    ]

    return (
        <>
            <Helmet>
                <title>Blackjack | Droplime</title>
            </Helmet>
            <main className={`bg-slate-900 min-h-screen`}>
                <div className={`grid grid-cols-12`}>
                    <section className={`col-span-4 bg-slate-800 p-4`}>
                        <div className="flex justify-center">
                            <Logo />
                        </div>
                        <FormikProvider value={form}>
                            <PlayAmount />
                            <div className={`grid grid-cols-2 gap-x-2 gap-y-2 py-2`}>
                                {actions.map((i, idx) => (
                                    <>
                                        <button key={idx} className={`font-medium px-3 py-2 bg-slate-700 text-white rounded-md`}>
                                            {i.title}
                                        </button>
                                    </>
                                ))}
                            </div>
                            <button className={`mt-4 px-5 py-4 w-full bg-lime-500 hover:bg-lime-600 rounded-sm font-medium text-white`}>
                                Play
                            </button>
                        </FormikProvider>
                    </section>
                    <section className={`col-span-8 h-42`}>
                        <div className={`grid grid-rows-6 gap-y-3`}>
                            <div className={`row-span-3`}>

                            </div>
                            <div className={`row-span-3`}>

                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}

export default BlackjackGame;