import { useState } from "react";
import RoulettePro from "react-roulette-pro";
import 'react-roulette-pro/dist/index.css';

interface SlideGameProps { };

/**
 * Slide is a realtime X-roulette type game where the user
 * chooses a play amount and a target multiplier. If the event
 * outcome is at least the chosen multiplier, the player receives
 * (amount * target multiplier), else they lose.
 */
const SlideGame = (props: SlideGameProps): JSX.Element => {
    const prizeList = [
        {
            "id": "a44c728d-8a0e-48ca-a963-3d5ce6dd41b0--mX8g75ukkqilCX7hIY5hP",
            "image": "https://i.ibb.co/ZLHZgKf/good-0.png",
            "text": "Monoblock Apple iMac 27"
        },
        {
            "id": "7d24b681-82d9-4fc0-b034-c82f9db11a59--zAR0LRNjizjHoa5PLudid",
            "image": "https://i.ibb.co/6Z6Xm9d/good-1.png",
            "text": "Apple MacBook Pro 13 Late 2020"
        },
        {
            "id": "9da9a287-952f-41bd-8c7a-b488938d7c7a--N3OIW4hTV85ya5kqAOjb8",
            "image": "https://i.ibb.co/T1M05LR/good-2.png",
            "text": "Apple iPhone 13 Pro Max 512GB"
        },
        {
            "id": "04106f3f-f99f-47e4-a62e-3c81fc8cf794--arLquCPJKO-yJ_aQMf-fy",
            "image": "https://i.ibb.co/Qbm8cNL/good-3.png",
            "text": "Apple MacBook Pro M1 13 256GB"
        },
        {
            "id": "23c551bf-8425-4ffd-b7c2-77c87844f89d--NUz5h6ZORGSxiBmU3kyAd",
            "image": "https://i.ibb.co/5Tpfs6W/good-4.png",
            "text": "MacBook Air 13"
        },
        {
            "id": "e4060930-538f-4bf7-ab8e-8d2aa05fba43--47Ng2f_PSMrJrtChpJUR0",
            "image": "https://i.ibb.co/64k8D1c/good-5.png",
            "text": "Apple iPad Pro 12.9"
        },
        {
            "id": "fb121804-e4f6-4fce-bdd6-1e3189172f37--EK8P2Px1W3U5K5XIiXmjb",
            "image": "https://i.ibb.co/TLjjsG3/good-6.png",
            "text": "Apple AirPods Max"
        },
        {
            "id": "26ee933e-0858-481d-afe8-b30d3829242a--DB-bswc9ySrpUT-VOkgaC",
            "image": "https://i.ibb.co/943n9PQ/good-7.png",
            "text": "Apple iPad Pro 12.9"
        },
        {
            "id": "c769c2b1-df6e-4e6e-8985-53b531527b35--VMPKTYLKeFZsveKtX8I6U",
            "image": "https://i.ibb.co/HVpYpsH/good-8.png",
            "text": "Apple Watch Series 6 44mm"
        },
        {
            "id": "bd9f86a3-9a72-4ba3-a071-3ea9cbc87cc1--BR61Zay_YHNlBbUAtgXAz",
            "image": "https://i.ibb.co/BnmJvZT/good-9.png",
            "text": "Apple Smart Keyboard iPad Pro 12.9"
        },
        {
            "id": "04106f3f-f99f-47e4-a62e-3c81fc8cf794--3NIvGA8DItR6LhAeDFdBo",
            "image": "https://i.ibb.co/Qbm8cNL/good-3.png",
            "text": "Apple MacBook Pro M1 13 256GB"
        },
        {
            "id": "fb121804-e4f6-4fce-bdd6-1e3189172f37--nPTerg0jhqCWxh7okp0x7",
            "image": "https://i.ibb.co/TLjjsG3/good-6.png",
            "text": "Apple AirPods Max"
        },
        {
            "id": "bd9f86a3-9a72-4ba3-a071-3ea9cbc87cc1--nz07DwzWrbR_wBtwsouEs",
            "image": "https://i.ibb.co/BnmJvZT/good-9.png",
            "text": "Apple Smart Keyboard iPad Pro 12.9"
        },
        {
            "id": "c769c2b1-df6e-4e6e-8985-53b531527b35--0UWierE8tpKrZPepCRCvw",
            "image": "https://i.ibb.co/HVpYpsH/good-8.png",
            "text": "Apple Watch Series 6 44mm"
        },
        {
            "id": "a44c728d-8a0e-48ca-a963-3d5ce6dd41b0--qojCLN8LQOg8tcZSmGNss",
            "image": "https://i.ibb.co/ZLHZgKf/good-0.png",
            "text": "Monoblock Apple iMac 27"
        },
        {
            "id": "9da9a287-952f-41bd-8c7a-b488938d7c7a--5O9H8fQ5zlRaC6IfnV7-z",
            "image": "https://i.ibb.co/T1M05LR/good-2.png",
            "text": "Apple iPhone 13 Pro Max 512GB"
        },
        {
            "id": "04106f3f-f99f-47e4-a62e-3c81fc8cf794--wQngUptumhJPsWgDUenta",
            "image": "https://i.ibb.co/Qbm8cNL/good-3.png",
            "text": "Apple MacBook Pro M1 13 256GB"
        },
        {
            "id": "e4060930-538f-4bf7-ab8e-8d2aa05fba43--__mmp2BlIe4ehA75qAHZ0",
            "image": "https://i.ibb.co/64k8D1c/good-5.png",
            "text": "Apple iPad Pro 12.9"
        },
        {
            "id": "04106f3f-f99f-47e4-a62e-3c81fc8cf794--CZ5qMVFN1yvgph37T7IY0",
            "image": "https://i.ibb.co/Qbm8cNL/good-3.png",
            "text": "Apple MacBook Pro M1 13 256GB"
        },
        {
            "id": "bd9f86a3-9a72-4ba3-a071-3ea9cbc87cc1--ZdihMSjy757Lk0p4t6Zqc",
            "image": "https://i.ibb.co/BnmJvZT/good-9.png",
            "text": "Apple Smart Keyboard iPad Pro 12.9"
        },
        {
            "id": "fb121804-e4f6-4fce-bdd6-1e3189172f37--G6WO1zpVM5c2boiv6gAth",
            "image": "https://i.ibb.co/TLjjsG3/good-6.png",
            "text": "Apple AirPods Max"
        },
        {
            "id": "a44c728d-8a0e-48ca-a963-3d5ce6dd41b0--k1hfkY6K7uLtLUVywh03_",
            "image": "https://i.ibb.co/ZLHZgKf/good-0.png",
            "text": "Monoblock Apple iMac 27"
        },
        {
            "id": "23c551bf-8425-4ffd-b7c2-77c87844f89d--PbF3p8UwMJen8NTpUIApz",
            "image": "https://i.ibb.co/5Tpfs6W/good-4.png",
            "text": "MacBook Air 13"
        },
        {
            "id": "7d24b681-82d9-4fc0-b034-c82f9db11a59--kusX--Zf8wyjxV-O5AZQz",
            "image": "https://i.ibb.co/6Z6Xm9d/good-1.png",
            "text": "Apple MacBook Pro 13 Late 2020"
        },
        {
            "id": "7d24b681-82d9-4fc0-b034-c82f9db11a59--7sIRV7GL3eMB7wnl6to9r",
            "image": "https://i.ibb.co/6Z6Xm9d/good-1.png",
            "text": "Apple MacBook Pro 13 Late 2020"
        },
        {
            "id": "bd9f86a3-9a72-4ba3-a071-3ea9cbc87cc1--8eMCLGxt0B94x7c_gkzQN",
            "image": "https://i.ibb.co/BnmJvZT/good-9.png",
            "text": "Apple Smart Keyboard iPad Pro 12.9"
        },
        {
            "id": "bd9f86a3-9a72-4ba3-a071-3ea9cbc87cc1--OlWUn9Ng9THOsq7Ebdz-x",
            "image": "https://i.ibb.co/BnmJvZT/good-9.png",
            "text": "Apple Smart Keyboard iPad Pro 12.9"
        },
        {
            "id": "bd9f86a3-9a72-4ba3-a071-3ea9cbc87cc1--20o7SqbKiJhiN7R-yTQr1",
            "image": "https://i.ibb.co/BnmJvZT/good-9.png",
            "text": "Apple Smart Keyboard iPad Pro 12.9"
        },
        {
            "id": "9da9a287-952f-41bd-8c7a-b488938d7c7a--5nvBK9ANtGQIdbD-bXy10",
            "image": "https://i.ibb.co/T1M05LR/good-2.png",
            "text": "Apple iPhone 13 Pro Max 512GB"
        },
        {
            "id": "fb121804-e4f6-4fce-bdd6-1e3189172f37--3NrUrHqWa3CJdXlRVI4s6",
            "image": "https://i.ibb.co/TLjjsG3/good-6.png",
            "text": "Apple AirPods Max"
        },
        {
            "id": "fb121804-e4f6-4fce-bdd6-1e3189172f37--CZMCwEhBkzlLPuuGUv36k",
            "image": "https://i.ibb.co/TLjjsG3/good-6.png",
            "text": "Apple AirPods Max"
        },
        {
            "id": "9da9a287-952f-41bd-8c7a-b488938d7c7a--k4kRbmcMwIlGscpf9K8uf",
            "image": "https://i.ibb.co/T1M05LR/good-2.png",
            "text": "Apple iPhone 13 Pro Max 512GB"
        },
        {
            "id": "bd9f86a3-9a72-4ba3-a071-3ea9cbc87cc1--miN5SYBz7sptaRGjYE6KK",
            "image": "https://i.ibb.co/BnmJvZT/good-9.png",
            "text": "Apple Smart Keyboard iPad Pro 12.9"
        },
        {
            "id": "c769c2b1-df6e-4e6e-8985-53b531527b35--oygaiamghSNgPpA_iGOlz",
            "image": "https://i.ibb.co/HVpYpsH/good-8.png",
            "text": "Apple Watch Series 6 44mm"
        },
        {
            "id": "23c551bf-8425-4ffd-b7c2-77c87844f89d--1_5h2XUyj7YFXr9z3xDie",
            "image": "https://i.ibb.co/5Tpfs6W/good-4.png",
            "text": "MacBook Air 13"
        },
        {
            "id": "fb121804-e4f6-4fce-bdd6-1e3189172f37--ZS4tTPf3YTqcuCfQhgZ9U",
            "image": "https://i.ibb.co/TLjjsG3/good-6.png",
            "text": "Apple AirPods Max"
        },
        {
            "id": "26ee933e-0858-481d-afe8-b30d3829242a--gluq3Tdw67GB1woH5ITb7",
            "image": "https://i.ibb.co/943n9PQ/good-7.png",
            "text": "Apple iPad Pro 12.9"
        },
        {
            "id": "7d24b681-82d9-4fc0-b034-c82f9db11a59--2tZ97C4VUiv8Lov8TnP1q",
            "image": "https://i.ibb.co/6Z6Xm9d/good-1.png",
            "text": "Apple MacBook Pro 13 Late 2020"
        },
        {
            "id": "e4060930-538f-4bf7-ab8e-8d2aa05fba43--In1K2nnm38JPeNO61j1Pm",
            "image": "https://i.ibb.co/64k8D1c/good-5.png",
            "text": "Apple iPad Pro 12.9"
        },
        {
            "id": "c769c2b1-df6e-4e6e-8985-53b531527b35--I_WntwdFQTbQYo-CX3mIZ",
            "image": "https://i.ibb.co/HVpYpsH/good-8.png",
            "text": "Apple Watch Series 6 44mm"
        },
        {
            "id": "a44c728d-8a0e-48ca-a963-3d5ce6dd41b0--ec2hwbdgAl6riSC_sEZhM",
            "image": "https://i.ibb.co/ZLHZgKf/good-0.png",
            "text": "Monoblock Apple iMac 27"
        },
        {
            "id": "7d24b681-82d9-4fc0-b034-c82f9db11a59--2Od2CF3m5gyvN0Ox6H-5w",
            "image": "https://i.ibb.co/6Z6Xm9d/good-1.png",
            "text": "Apple MacBook Pro 13 Late 2020"
        },
        {
            "id": "9da9a287-952f-41bd-8c7a-b488938d7c7a--WwQNZuJMZi-rUwUgluQ3N",
            "image": "https://i.ibb.co/T1M05LR/good-2.png",
            "text": "Apple iPhone 13 Pro Max 512GB"
        },
        {
            "id": "04106f3f-f99f-47e4-a62e-3c81fc8cf794--Fm08vmqwFlP9KhHiIQZ4w",
            "image": "https://i.ibb.co/Qbm8cNL/good-3.png",
            "text": "Apple MacBook Pro M1 13 256GB"
        },
        {
            "id": "23c551bf-8425-4ffd-b7c2-77c87844f89d--4eHfZHblIAb5a4HLOF4UT",
            "image": "https://i.ibb.co/5Tpfs6W/good-4.png",
            "text": "MacBook Air 13"
        },
        {
            "id": "e4060930-538f-4bf7-ab8e-8d2aa05fba43--hREPkkiecqzILYoWt-F6z",
            "image": "https://i.ibb.co/64k8D1c/good-5.png",
            "text": "Apple iPad Pro 12.9"
        },
        {
            "id": "fb121804-e4f6-4fce-bdd6-1e3189172f37--SiMovZLZvRDU5Z2k7O_Pb",
            "image": "https://i.ibb.co/TLjjsG3/good-6.png",
            "text": "Apple AirPods Max"
        },
        {
            "id": "26ee933e-0858-481d-afe8-b30d3829242a--I1ubHGbakcdyuWOCDGLEx",
            "image": "https://i.ibb.co/943n9PQ/good-7.png",
            "text": "Apple iPad Pro 12.9"
        },
        {
            "id": "c769c2b1-df6e-4e6e-8985-53b531527b35--16XPzaHlGSlGxGcH4wpLA",
            "image": "https://i.ibb.co/HVpYpsH/good-8.png",
            "text": "Apple Watch Series 6 44mm"
        },
        {
            "id": "bd9f86a3-9a72-4ba3-a071-3ea9cbc87cc1--igaNvo5iieLA4oFuGijuQ",
            "image": "https://i.ibb.co/BnmJvZT/good-9.png",
            "text": "Apple Smart Keyboard iPad Pro 12.9"
        },
        {
            "id": "a44c728d-8a0e-48ca-a963-3d5ce6dd41b0--Vs3_4ig6SnAr5piZk-32W",
            "image": "https://i.ibb.co/ZLHZgKf/good-0.png",
            "text": "Monoblock Apple iMac 27"
        },
        {
            "id": "fb121804-e4f6-4fce-bdd6-1e3189172f37--kFpREIL3L6dxYhc4nTtRQ",
            "image": "https://i.ibb.co/TLjjsG3/good-6.png",
            "text": "Apple AirPods Max"
        },
        {
            "id": "c769c2b1-df6e-4e6e-8985-53b531527b35--7mzGQuMPzI8p77JA8OL81",
            "image": "https://i.ibb.co/HVpYpsH/good-8.png",
            "text": "Apple Watch Series 6 44mm"
        },
        {
            "id": "7d24b681-82d9-4fc0-b034-c82f9db11a59--19vJi1Kgnjf47j6f5Bhl7",
            "image": "https://i.ibb.co/6Z6Xm9d/good-1.png",
            "text": "Apple MacBook Pro 13 Late 2020"
        },
        {
            "id": "c769c2b1-df6e-4e6e-8985-53b531527b35--NIeYV2x48zB-hy3G102RY",
            "image": "https://i.ibb.co/HVpYpsH/good-8.png",
            "text": "Apple Watch Series 6 44mm"
        },
        {
            "id": "fb121804-e4f6-4fce-bdd6-1e3189172f37--7KNRHZvyCMJ2ftE1JjAdr",
            "image": "https://i.ibb.co/TLjjsG3/good-6.png",
            "text": "Apple AirPods Max"
        },
        {
            "id": "a44c728d-8a0e-48ca-a963-3d5ce6dd41b0--yyARwtRQqYyvf3CR495vK",
            "image": "https://i.ibb.co/ZLHZgKf/good-0.png",
            "text": "Monoblock Apple iMac 27"
        },
        {
            "id": "a44c728d-8a0e-48ca-a963-3d5ce6dd41b0--PcFaMAs6BX3lLNzMHSAUm",
            "image": "https://i.ibb.co/ZLHZgKf/good-0.png",
            "text": "Monoblock Apple iMac 27"
        },
        {
            "id": "a44c728d-8a0e-48ca-a963-3d5ce6dd41b0--1jeXvQ00jsP9MhlwfLzQ2",
            "image": "https://i.ibb.co/ZLHZgKf/good-0.png",
            "text": "Monoblock Apple iMac 27"
        },
        {
            "id": "7d24b681-82d9-4fc0-b034-c82f9db11a59--8MGvZehQ89egmGpghRegp",
            "image": "https://i.ibb.co/6Z6Xm9d/good-1.png",
            "text": "Apple MacBook Pro 13 Late 2020"
        }
    ];

    const [start, setStart] = useState(false);

    const play = () => {
        setStart(true);
    }

    const end = () => {
        setStart(false);
    }

    return (
        <>

            <RoulettePro
                start={start}
                prizes={prizeList}
                prizeIndex={47}
                spinningTime={3}
                onPrizeDefined={end}
            />
            <button onClick={play}>
                Play
            </button>
        </>
    )
}

export default SlideGame;