import { useEffect, useState } from "react";
import Logo from "../../components/Logo";
import PlayAmount from "../../components/PlayAmount";
import { FormikProvider, useFormik } from "formik";
import { Tab } from '@headlessui/react';
import { Helmet } from "react-helmet";
import './styles.css';

interface CoinFlipGameProps { };

const CoinFlipGame = (props: CoinFlipGameProps): JSX.Element => {
    const [result, setResult] = useState<'heads' | 'tails' | undefined>()

    const form = useFormik<{ amount: number; autoplay_count: number; is_autoplay: boolean; }>({
        initialValues: {
            amount: 10,
            autoplay_count: 0,
            is_autoplay: false,
        },
        onSubmit: async (values, { setSubmitting }) => {
            coinToss()
        }
    })

    const coinToss = () => {
        if (Math.random() < 0.5) {
            setResult("heads");
        } else {
            setResult("tails");
        }
    }



    useEffect(() => {
        window.addEventListener(`message`, (ev) => {
            const data = ev.data;
            if (data['type'] !== null) {
                switch (data.type) {
                    case `init`: {
                        const payload = JSON.parse(data.message);
                    }
                }
            }
        })
        return () => {
            window.removeEventListener(`message`, () => null)
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Coin flip | Droplime</title>
            </Helmet>
            <main className="bg-slate-900 min-h-screen">
                <div className="grid grid-cols-12 ">
                    <section className="col-span-4 bg-slate-800 p-4">
                        <div className={`flex justify-center`}>
                            <Logo />
                        </div>
                        <FormikProvider value={form}>
                            <Tab.Group
                                onChange={(index) => {
                                    form.setFieldValue(`is_autoplay`, index === 1);
                                }}>
                                <Tab.List className={`w-full flex justify-evenly focus:outline-none`}>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            /* Use the `selected` state to conditionally style the selected tab. */
                                            <button
                                                className={
                                                    `${selected ? 'bg-lime-500 text-white' : 'bg-white text-lime-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-100'}`
                                                }
                                            >
                                                Manual
                                            </button>
                                        )}
                                    </Tab>
                                    <Tab className={`w-full focus:outline-none`}>
                                        {({ selected }) => (
                                            <button
                                                className={
                                                    `${selected ? 'bg-lime-500 text-white' : 'bg-white text-lime-500'} px-5 py-4 w-full font-medium ${!selected && 'hover:bg-slate-100'}`
                                                }
                                            >
                                                Auto
                                            </button>
                                        )}
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className={`py-5`}>
                                    <Tab.Panel className={`w-full`}>
                                        <PlayAmount />
                                        <section id={`play-settings`} className={`space-y-3 mt-4`}>
                                            <button
                                                onClick={coinToss}
                                                className={`px-5 py-2 w-full bg-lime-500 hover:bg-lime-600 rounded-sm font-medium text-white`}>Play</button>
                                        </section>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <FormikProvider value={form}>
                                            <section className={`space-y-4`}>
                                                <div>
                                                    <label htmlFor='autoplay_count' className='text-sm font-medium mb-2 text-white'>Number of plays</label>
                                                    <input
                                                        name={`autoplay_count`}
                                                        value={form.values.autoplay_count}
                                                        onChange={form.handleChange}
                                                        disabled={form.isSubmitting}
                                                        onBlur={form.handleBlur}
                                                        className={`flex-8 w-full rounded-sm border py-2 px-3 focus:outline-none focus:ring-1 focus:border-lime-500`}
                                                    />
                                                </div>
                                                <PlayAmount />
                                                <button type={`submit`} className={`px-5 py-2 w-full bg-lime-500 hover:bg-lime-600 rounded-sm font-medium text-white`}>Start autoplay</button>
                                            </section>
                                        </FormikProvider>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </FormikProvider>
                    </section>
                    <section className={`col-span-8 h-70 flex px-4`}>
                        <div className={`m-auto`}>
                            <div id="coin" className={result} key={+new Date()}>
                                <div className="w-64 h-64 side-a flex flex-col justify-center">
                                    <p>T</p>
                                </div>
                                <div className="side-b flex flex-col justify-center">
                                    <p>H</p>
                                </div>
                            </div>
                            <button onClick={() => coinToss()} className={`text-white`}>Toss coin</button>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}

export default CoinFlipGame;