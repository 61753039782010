

interface LogoProps { };

const Logo = (props: LogoProps): JSX.Element => {
    return (
        <>
            <div className={`my-auto flex flex-row gap-x-3 py-4`}>
                <img src={`/logo.svg`} width={24} height={24} />
                <div className='my-auto'>
                    <p className={`font-medium dark:text-white`}>Mocash</p>
                </div>
            </div>
        </>
    )
}

export default Logo;