import { useFormikContext } from "formik";


interface PlayAmountProps { };

const PlayAmount = (props: PlayAmountProps): JSX.Element => {

    const form = useFormikContext<any>();

    const halvePlayAmount = () => {
        const newPlayAmount = form.values.amount / 2
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    const doublePlayAmount = () => {
        const newPlayAmount = form.values.amount * 2
        // todo add balance
        if (newPlayAmount >= 0.01) {
            form.setFieldValue(`amount`, newPlayAmount)
        }
    }

    return (
        <>
            <div>
                <label htmlFor='amount' className='text-sm font-medium mb-2 text-white'>Play amount</label>
                <div className={`flex justify-start my-3`}>
                    <input
                        onChange={form.handleChange}
                        value={form.values.amount}
                        disabled={form.isSubmitting}
                        name={`amount`}
                        type={`number`}
                        min={1}
                        step={10}
                        placeholder='amount'
                        className={`dark:bg-slate-700 text-white text-center flex-8 w-full rounded-l-sm py-2 px-3 focus:outline-none focus:ring-1 focus:border-emerald-500`} />
                    <button
                        onClick={halvePlayAmount}
                        className={`dark:bg-slate-800 text-white flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium`}>½</button>
                    <button
                        onClick={doublePlayAmount}
                        className={`dark:bg-slate-800 text-white flex-1 px-3 bg-slate-50 hover:bg-emerald-500 text-xs font-medium rounded-r-sm`}>2x</button>
                </div>
            </div>
        </>
    )
}

export default PlayAmount;