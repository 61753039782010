import { DetailedHTMLProps } from "react";

export interface ButtonProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
}

const Button = (props: ButtonProps) => {
    return (
        <>
          <button {...props} className={`shadow-md px-5 py-2 w-full bg-emerald-500 hover:bg-emerald-600 rounded-sm font-medium text-white`}/>
        </>
    )
}
export default Button;