import { Form, FormikProvider, useFormik } from "formik";
import PlayAmount from "../../components/PlayAmount";
import Button from "../../components/Button";
import { useRef, useState } from "react";
import { getHigherMultiplier, getLowerMultiplier } from "../../lib/hilo";
import { CaretCircleDown, CaretCircleUp } from "@phosphor-icons/react";
import { Helmet } from "react-helmet";

type CardSuit = 'clubs' | 'diamonds' | 'hearts' | 'spades'

type Card = {
    suit: CardSuit,
    rank: number,
}

interface HiLoGameProps { };

const HiLoGame = (props: HiLoGameProps): JSX.Element => {

    const [isActive, setIsActive] = useState<boolean>(false);

    const [cardHistory, setCardHistory] = useState<Array<Card>>([

    ])

    const currentCardContainerRef = useRef<HTMLImageElement>(null);

    const [card, setCard] = useState<Card>({
        rank: Math.floor(Math.random() * 14),
        suit: 'spades'
    });

    const formik = useFormik({
        initialValues: {
            wager: undefined,
        },
        onSubmit: async () => {

        }
    })

    const getSuit = (): CardSuit => {
        const outcome = Math.floor(Math.random() * 4);

        switch (outcome) {
            case 0: return 'clubs'
            case 1: return 'diamonds'
            case 2: return 'hearts'
            case 3: return 'spades'
            default: return 'diamonds'
        }

    }

    const skipCard = () => {
        currentCardContainerRef?.current?.classList.add('magictime', 'puffIn')
        setCard({
            suit: getSuit(),
            rank: Math.floor(Math.random() * 14)
        })
    }

    return (
        <>
            <Helmet>
                <title>HiLo</title>
            </Helmet>
            <main>
                <FormikProvider value={formik}>
                    <div className="grid md:grid-cols-12 rounded-md">
                        <div className="col-span-3 px-2 py-3 space-y-3 bg-slate-700">
                            <PlayAmount />
                            <div>
                                <button
                                    className={`shadow-md px-5 py-4 w-full bg-slate-600 hover:bg-slate-700 rounded-sm font-medium text-white`}>
                                    Higher or Same
                                </button>
                            </div>
                            <div>
                                <button
                                    className={`shadow-md px-5 py-4 w-full bg-slate-600 hover:bg-slate-700 rounded-sm font-medium text-white`}>
                                    Lower or Same
                                </button>
                            </div>
                            <Button onClick={skipCard}>Skip card</Button>
                            <Button>{isActive ? 'Play' : 'Cash out'}</Button>
                        </div>
                        <div className="col-span-9 space-y-4 px-3 bg-slate-800">
                            <div className="grid grid-cols-3 gap-x-3 rounded-lg px-3 py-4 ">
                                <div className="col-span-1 m-auto">
                                    <p className="dark:text-white">King is the highest</p>
                                </div>
                                <div className="col-span-1 flex justify-center">
                                    <img
                                        ref={currentCardContainerRef}
                                        src={`/cards/PNG/black/${card.suit}-${card.rank}.png`}
                                        width={160}
                                    />
                                </div>
                                <div className="col-span-1 m-auto">
                                    <p className="dark:text-white">Ace is the lowest</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-3 rounded-lg px-3 py-4 bg-slate-900">
                                <div className="col-span-1">
                                    <p className="font-medium text-slate-400">Profit Higher</p>
                                    <div className="w-full text-white px-3 py-2 font-medium bg-slate-700 rounded-md flex gap-x-2">
                                        <div className="my-auto">
                                            <CaretCircleUp weight={`fill`} size={20} />
                                        </div>
                                        {getHigherMultiplier(card.rank).toFixed(2)}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <p className="font-medium text-slate-400">Profit Lower</p>
                                    <p className="shadow-md w-full px-3 py-2 font-medium bg-slate-700 rounded-md text-white flex gap-x-2">
                                        <div className="my-auto">
                                            <CaretCircleDown weight={`fill`} size={20} />
                                        </div>
                                        <p className="text-white">{getLowerMultiplier(card.rank).toFixed(2)}</p>
                                    </p>
                                </div>
                                <div className="col-span-1">
                                    <p className="font-medium text-slate-400">Total Win</p>
                                    <p className="w-full px-3 py-2 font-medium bg-slate-700 rounded-md text-white flex gap-x-2">
                                        <div className="my-auto">
                                            <CaretCircleDown weight={`fill`} size={20} />
                                        </div>
                                        {getLowerMultiplier(card.rank).toFixed(2)}
                                    </p>
                                </div>
                            </div>
                            <section id="play-history" className="flex justify-start gap-x-3 pt-2 pb-6 w-full overflow-x-scroll">
                                {cardHistory.map((i, idx) => (
                                    <>
                                        <img
                                            key={idx}
                                            src={`/cards/PNG/black/${card.suit}-${card.rank}.png`}
                                            width={96}
                                        />
                                    </>
                                ))}
                            </section>
                        </div>
                    </div>
                </FormikProvider>
            </main>
        </>
    )
}

export default HiLoGame;